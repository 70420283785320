import moment from 'moment';
import { photoTypes, BUDGETS } from './globals';

// Questions
export const QUESTION_DATE = 'QUESTION_DATE';
export const QUESTION_DESCRIPTION = 'QUESTION_DESCRIPTION';
export const QUESTION_EMAIL = 'QUESTION_EMAIL';
export const QUESTION_LOCATION = 'QUESTION_LOCATION';
export const QUESTION_NAMES = 'QUESTION_NAMES';
export const QUESTION_TYPES = 'QUESTION_TYPES';
export const QUESTION_ORGCODE = 'QUESTION_ORGCODE';
export const QUESTION_ORGNAME = 'QUESTION_ORGNAME';
export const QUESTION_WWW = 'QUESTION_WWW';
export const QUESTION_PASSWORD = 'QUESTION_PASSWORD';
export const QUESTION_PASSWORD_CONFIRM = 'QUESTION_PASSWORD_CONFIRM';
export const QUESTION_PROPOSAL = 'QUESTION_PROPOSAL';
export const QUESTION_PRICE = 'QUESTION_PRICE';
export const QUESTION_VAT = 'QUESTION_VAT';
export const QUESTION_TERMS = 'QUESTION_TERMS';
export const QUESTION_BUDGET = 'QUESTION_BUDGET';

const datePlaceholder = moment().add(1, 'years').format('DD.MM.YYYY');

// ------------------------------------
// Questions
// ------------------------------------
export const rfpQuestions = [
  {
    text: 'Mitä kuvia toivotte',
    slug: QUESTION_TYPES,
    tag: 'fieldset',
    type: 'checkbox',
    placeholder: photoTypes,
    defaultAnswer: [],
  },
  {
    text: 'Milloin on suuri päivänne',
    slug: QUESTION_DATE,
    tag: 'input',
    type: 'text',
    placeholder: datePlaceholder,
    defaultAnswer: '',
    maxlength: '10',
  },
  {
    text: 'Millä paikkakunnalla häitä juhlitaan',
    slug: QUESTION_LOCATION,
    type: 'text',
    tag: 'input',
    placeholder: 'Naantali',
    defaultAnswer: '',
    maxlength: '60',
  },
  {
    text: 'Ketkä menevät naimisiin',
    slug: QUESTION_NAMES,
    tag: 'input',
    type: 'text',
    placeholder: 'Minttu & Ville',
    defaultAnswer: '',
    maxlength: '60',
  },
  {
    text: 'Mikä on budjettinne',
    slug: QUESTION_BUDGET,
    tag: 'fieldset',
    type: 'radio',
    placeholder: BUDGETS,
    defaultAnswer: '',
  },
  {
    text: 'Kertokaa tarkemmin toiveistanne kuvaukselle',
    slug: QUESTION_DESCRIPTION,
    type: 'text',
    tag: 'textarea',
    placeholder:
      'Sana on vapaa. Mitä enemmän tietoa häistänne, sitä varmemmin saatte hyviä vastauksia.',
    defaultAnswer: '',
    maxlength: '10000',
  },
  {
    text: 'Minne lähetämme tarjoukset',
    slug: QUESTION_EMAIL,
    type: 'email',
    tag: 'input',
    placeholder: 'osoite@email.net',
    defaultAnswer: '',
    maxlength: '60',
  },
];

export const signUpQuestions = [
  {
    text: 'Y-tunnus',
    slug: QUESTION_ORGCODE,
    tag: 'input',
    type: 'text',
    placeholder: '1234567-8',
    defaultAnswer: '',
    maxlength: '10',
  },
  {
    text: 'Yritysnimi, toiminimi tai vastaava',
    slug: QUESTION_ORGNAME,
    tag: 'input',
    type: 'text',
    placeholder: 'Oy yritys Ab',
    defaultAnswer: '',
    maxlength: '100',
  },
  {
    text: 'Portfolion osoite',
    slug: QUESTION_WWW,
    tag: 'input',
    type: 'text',
    placeholder: 'https://www.portfolioni.fi',
    defaultAnswer: '',
    maxlength: '100',
  },
  {
    text: 'Sähköpostiosoite',
    slug: QUESTION_EMAIL,
    type: 'email',
    tag: 'input',
    placeholder: 'osoite@email.net',
    defaultAnswer: '',
    maxlength: '60',
  },
];

export const loginQuestions = [
  {
    text: 'Sähköpostiosoite',
    slug: QUESTION_EMAIL,
    tag: 'input',
    type: 'email',
    placeholder: 'osoite@email.net',
    defaultAnswer: '',
    autoComplete: 'username',
    maxlength: '60',
  },
  {
    text: 'Salasana',
    slug: QUESTION_PASSWORD,
    tag: 'input',
    type: 'password',
    placeholder: '',
    defaultAnswer: '',
    autoComplete: 'password',
    maxlength: '60',
  },
];

export const passwordQuestions = [
  {
    text: 'Salasana',
    slug: QUESTION_PASSWORD,
    tag: 'input',
    type: 'password',
    placeholder: '',
    defaultAnswer: '',
    autocomplete: 'new-password',
    maxlength: '60',
  },
  {
    text: 'Salasana uudelleen',
    slug: QUESTION_PASSWORD_CONFIRM,
    tag: 'input',
    type: 'password',
    placeholder: '',
    defaultAnswer: '',
    autocomplete: 'new-password',
    maxlength: '60',
  },
];

export const forgotQuestions = [
  {
    text: 'Sähköpostiosoite',
    slug: QUESTION_EMAIL,
    tag: 'input',
    type: 'email',
    placeholder: 'osoite@email.net',
    defaultAnswer: '',
    autoComplete: 'username',
    maxlength: '60',
  },
];

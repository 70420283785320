import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, call } from 'redux-saga/effects';
import moment from 'moment';
import * as globals from '../constants/globals';
import * as adminSaga from './adminSaga';
import * as adminActions from '../actions/adminActions';
import sagaError from './sagaError';

const doChooseProposal = (payload) => {
  const body = JSON.stringify(payload);
  const request = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${globals.CHOOSE_PROPOSAL_URL}`, request);
};

const doCancelProposal = (payload) => {
  const body = JSON.stringify(payload);
  const request = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${globals.CANCEL_PROPOSAL_URL}`, request);
};

const createNewProposal = (data) => {
  const proposal = {};
  moment.locale('fi');
  proposal.createdOn = moment().format();
  proposal.shooter = data.loggedInShooterId;
  proposal.requestForProposal = data.request;
  proposal.description = data.desc;
  proposal.proposedAmount = data.price;
  proposal.taxRate = data.vat;
  // Make the call
  const body = JSON.stringify(proposal);
  const request = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(globals.PROPOSALS_URL, request);
};

export function* submitProposalForm({ payload }) {
  try {
    yield put(adminActions.setSubmittingProposal(true));
    yield put(showLoading());
    const response = yield createNewProposal(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(adminSaga.fetchProposalsOfLoggedInShooter);
    toast.success('Tarjous jätetty');
  } catch (error) {
    sagaError(error, 'Tarjouksen jättäminen ei onnistunut');
  } finally {
    yield put(adminActions.setSubmittingProposal(false));
    yield put(hideLoading());
  }
}

export function* chooseProposal({ payload }) {
  try {
    yield put(adminActions.setChoosingProposal(true));
    yield put(showLoading());
    const response = yield doChooseProposal(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const { affirmedBy } = payload;
    if (affirmedBy === globals.AFFIRMER_CUSTOMER) {
      yield call(adminSaga.fetchProposalsOfRfp);
      yield call(adminSaga.fetchRequest);
    }
    if (affirmedBy === globals.AFFIRMER_SHOOTER) {
      yield call(adminSaga.fetchProposalsOfLoggedInShooter);
      yield call(adminSaga.fetchRequests);
    }
    toast.success('Tarjous valittu');
  } catch (error) {
    sagaError(error, 'Tarjouksen valitseminen ei onnistunut');
  } finally {
    yield put(adminActions.setChoosingProposal(false));
    yield put(hideLoading());
  }
}

export function* cancelProposal({ payload }) {
  try {
    yield put(adminActions.setCancelingProposal(true));
    yield put(showLoading());
    const response = yield doCancelProposal(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(adminSaga.fetchProposalsOfLoggedInShooter);
    yield call(adminSaga.fetchRequests);
    toast.success('Tarjous peruttu');
  } catch (error) {
    sagaError(error, 'Tarjouksen peruuttaminen ei onnistunut');
  } finally {
    yield put(adminActions.setCancelingProposal(false));
    yield put(hideLoading());
  }
}

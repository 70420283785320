import axios from 'axios';
import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as globals from '../constants/globals';
import * as adminActions from '../actions/adminActions';
import * as shooterActions from '../actions/shooterActions';
import * as requestActions from '../actions/requestActions';
import * as proposalActions from '../actions/proposalActions';
import { arrayToObject } from '../helpers/helperFunctions';
import sagaError from './sagaError';

// ------------------------------------
// Actions
// ------------------------------------
function* doSaveProfile(profileData) {
  const body = JSON.stringify(profileData);
  return yield fetch(globals.SHOOTER_URL, {
    credentials: 'same-origin',
    method: 'PUT',
    mode: 'same-origin',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function* doSaveProfileImage(formData) {
  return yield axios.post(`${globals.SHOOTER_URL}/profile-image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function* doFetchProposalsOfRfp() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(`${globals.PROPOSALS_URL}/rfp/mine`, request);
}

function* doFetchProposalsOfLoggedInShooter() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(`${globals.PROPOSALS_URL}/shooter/mine`, request);
}

function* doFetchProposalsAll() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(`${globals.PROPOSALS_URL}/all`, request);
}

function* doFetchShooter() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(globals.SHOOTER_URL, request);
}

function* doFetchShootersAll() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(globals.SHOOTERS_ALL_URL, request);
}

function* doFetchShootersOfRfp() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    redirect: 'follow',
  };
  return yield fetch(`${globals.SHOOTERS_URL}/rfp`, request);
}

function* doFetchRequest() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
  };
  return yield fetch(globals.RFP_URL, request);
}

/**
 * Fetch the logged in shooter details
 *
 * @name fetchShooter
 * @function
 */
export function* fetchShooter() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingShooters(true));
    const response = yield doFetchShooter();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const shooter = { [result._id]: result };
    yield put(shooterActions.setShooters(shooter));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingShooters(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch all shooters
 *
 * @name fetchShootersAll
 * @function
 */
export function* fetchShootersAll() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingShooters(true));
    const response = yield doFetchShootersAll();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const shooters = arrayToObject(result);
    yield put(shooterActions.setShooters(shooters));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingShooters(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch shooters that have made proposals to a given rfp
 *
 * @name fetchShootersOfRfp
 * @function
 * @param {string} rfpId RFP ID.
 */
export function* fetchShootersOfRfp() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingShooters(true));
    const response = yield doFetchShootersOfRfp();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const shooters = arrayToObject(result);
    yield put(shooterActions.setShooters(shooters));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingShooters(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch request for proposal details for logged in rfp.
 *
 * @name fetchRequest
 * @function
 */
export function* fetchRequest() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingRequests(true));
    const response = yield doFetchRequest();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const request = { [result._id]: result };
    yield put(requestActions.setRequests(request));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingRequests(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch all requests for proposal.
 *
 * @name fetchRequests
 * @function
 */
export function* fetchRequests() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingRequests(true));
    const response = yield fetch(globals.RFPS_ALL_URL);
    if (!response.ok) {
      const error = yield response.text();
      throw new Error(error);
    }
    const result = yield response.json();
    const requests = arrayToObject(result);
    yield put(requestActions.setRequests(requests));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingRequests(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch proposals that the logged in shooter has made.
 *
 * @name fetchProposalsOfLoggedInShooter
 * @function
 */
export function* fetchProposalsOfLoggedInShooter() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingProposals(true));
    const response = yield doFetchProposalsOfLoggedInShooter();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const proposals = arrayToObject(result);
    yield put(proposalActions.setProposals(proposals));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingProposals(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch proposals that the logged in shooter has made.
 *
 * @name fetchProposalsall
 * @function
 */
export function* fetchProposalsAll() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingProposals(true));
    const response = yield doFetchProposalsAll();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const proposals = arrayToObject(result);
    yield put(proposalActions.setProposals(proposals));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingProposals(false));
    yield put(hideLoading());
  }
}

/**
 * Fetch proposals that have been made to a request for proposal
 * for logged in rfp.
 *
 * @name fetchProposalsOfRfp
 * @function
 */
export function* fetchProposalsOfRfp() {
  try {
    yield put(showLoading());
    yield put(adminActions.setFetchingProposals(true));
    const response = yield doFetchProposalsOfRfp();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const proposals = arrayToObject(result);
    yield put(proposalActions.setProposals(proposals));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(adminActions.setFetchingProposals(false));
    yield put(hideLoading());
  }
}

/**
 * Saves shooter profile
 *
 * @name saveShooterProfile
 * @function
 * @param {formdata} payload New profile data
 */
export function* saveShooterProfile({ payload }) {
  yield put(adminActions.setSavingProfile(true));
  yield put(showLoading());
  try {
    const response = yield doSaveProfile(payload);
    if (!response.ok) {
      throw new Error(response);
    }
    yield fetchShooter();
    toast.success('Tiedot tallennettu');
  } catch (error) {
    sagaError(error, 'Tallennus ei onnistunut');
  } finally {
    yield put(adminActions.setSavingProfile(false));
    yield put(hideLoading());
  }
}

/**
 * Saves shooter profile image
 *
 * @name saveShooterProfileImage
 * @function
 * @param {formdata} payload New profile image data
 */
export function* saveShooterProfileImage({ payload }) {
  yield put(adminActions.setSavingProfile(true));
  yield put(showLoading());
  try {
    const response = yield doSaveProfileImage(payload);
    if (response.status !== 200) {
      throw new Error(response);
    }
    yield fetchShooter();
    toast.success('Tiedot tallennettu');
  } catch (error) {
    sagaError(error, 'Tallennus ei onnistunut');
  } finally {
    yield put(adminActions.setSavingProfile(false));
    yield put(hideLoading());
  }
}

export const SET_GALLERY = 'SET_GALLERY';
export const ADD_GALLERY_IMAGE = 'ADD_GALLERY_IMAGE';
export const CHANGE_GALLERY_IMAGE = 'CHANGE_GALLERY_IMAGE';
export const DELETE_GALLERY_IMAGE = 'DELETE_GALLERY_IMAGE';
export const FETCH_GALLERY = 'FETCH_GALLERY';
export const SET_FETCHING_GALLERY = 'SET_FETCHING_GALLERY';
export const SAVE_GALLERY_IMAGE = 'SAVE_GALLERY_IMAGE';

export const setGallery = (shooterId, gallery) => ({
  type: SET_GALLERY,
  shooterId,
  gallery,
});

export const saveGalleryImage = (payload) => ({
  type: SAVE_GALLERY_IMAGE,
  payload,
});

export const deleteGalleryImage = (payload) => ({
  type: DELETE_GALLERY_IMAGE,
  payload,
});

export const changeGalleryImage = (payload) => ({
  type: CHANGE_GALLERY_IMAGE,
  payload,
});

export const fetchGallery = () => ({
  type: FETCH_GALLERY,
});

export const setFetchingGallery = (payload) => ({
  type: SET_FETCHING_GALLERY,
  payload,
});

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, select } from 'redux-saga/effects';
import * as globals from '../constants/globals';
import * as createPasswordActions from '../actions/createPasswordActions';
import * as formActions from '../actions/formActions';
import * as selectors from '../selectors/selectors';
import sagaError from './sagaError';

/**
 * Checks the login methods for the shooter from the API.
 *
 * @name doCheckShooterLoginMethod
 * @function
 * @returns {object} Fetch response.
 */
function* doCheckShooterLoginMethods() {
  const shooterId = yield select(selectors.passwordCreatorId);
  const request = {
    method: 'GET',
    redirect: 'follow',
  };
  return yield fetch(
    `${globals.SHOOTERS_URL}/${shooterId}/login-methods`,
    request
  );
}

function* doCreatePassword() {
  const credentials = {};
  credentials.shooterId = yield select(selectors.passwordCreatorId);
  credentials.password = yield select(selectors.password);
  const body = JSON.stringify(credentials);
  const request = {
    credentials: 'same-origin',
    method: 'PUT',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return yield fetch(
    `${globals.SHOOTERS_URL}/conf/${credentials.shooterId}`,
    request
  );
}

/**
 * Handles the checking of login methods, initiated by rootSaga.
 * Sets the resulting login methods into state.
 *
 * @name checkLoginMethods
 * @function
 */
export function* checkLoginMethods() {
  yield put(showLoading());
  try {
    yield put(createPasswordActions.setCheckingLoginMethods(true));
    const response = yield doCheckShooterLoginMethods();
    const result = yield response.json();
    if (response.ok) {
      yield put(createPasswordActions.setLoginMethods(result));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(createPasswordActions.setCheckingLoginMethods(false));
    yield put(hideLoading());
  }
}

/**
 * Creates a password and dispatches a form submit result from the outcome.
 *
 * @name createPassword
 * @function
 */
export function* createPassword() {
  try {
    yield put(createPasswordActions.setCreatingPassword(true));
    const response = yield doCreatePassword();
    const result = yield response.json();
    if (response.ok) {
      yield put(formActions.setFormSubmitResult(globals.FORM_SUBMIT_SUCCESS));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    sagaError(error);
    yield put(formActions.setFormSubmitResult(globals.FORM_SUBMIT_FAIL));
  } finally {
    yield put(createPasswordActions.setCreatingPassword(false));
  }
}

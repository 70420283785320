export const SET_PROPOSAL_QUESTIONS = 'SET_PROPOSAL_QUESTIONS';
export const ASK_QUESTION = 'ASK_QUESTION';
export const ANSWER_QUESTION = 'ANSWER_QUESTION';

export const setProposalQuestions = (payload) => ({
  type: SET_PROPOSAL_QUESTIONS,
  payload,
});

export const askQuestion = (payload) => ({
  type: ASK_QUESTION,
  payload,
});

export const answerQuestion = (payload) => ({
  type: ANSWER_QUESTION,
  payload,
});

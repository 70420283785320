import { all, takeEvery } from 'redux-saga/effects';
import * as adminActions from '../actions/adminActions';
import * as authActions from '../actions/authActions';
import * as confirmationActions from '../actions/confirmationActions';
import * as createPasswordActions from '../actions/createPasswordActions';
import * as feedbackActions from '../actions/feedbackActions';
import * as formActions from '../actions/formActions';
import * as galleryActions from '../actions/galleryActions';
import * as proposalActions from '../actions/proposalActions';
import * as questionActions from '../actions/questionActions';
import * as requestActions from '../actions/requestActions';
import * as viewActions from '../actions/viewActions';
import * as adminSaga from './adminSaga';
import * as analyticsSaga from './analyticsSaga';
import * as authSaga from './authSaga';
import * as confirmationSaga from './confirmationSaga';
import * as createPasswordSaga from './createPasswordSaga';
import * as feedbackSaga from './feedbackSaga';
import * as formSaga from './formSaga';
import * as gallerySaga from './gallerySaga';
import * as proposalSaga from './proposalSaga';
import * as questionSaga from './questionSaga';
import * as requestSaga from './requestSaga';
import * as viewSaga from './viewSaga';

export default function* rootSaga() {
  yield all([
    // View initialization.
    takeEvery(viewActions.INIT_VIEW, viewSaga.initView),

    // Form slugs.
    takeEvery(formActions.SET_FORM_TYPE, formSaga.setFormSlug),

    // Form submitting.
    takeEvery(formActions.SUBMIT_FORM, formSaga.handleSubmitForm),

    // Confirmations process.
    takeEvery(
      confirmationActions.SET_CONFIRMATION_RECORD_ID,
      confirmationSaga.getConfirmationRecordType
    ),
    takeEvery(
      confirmationActions.SET_CONFIRMATION_RECORD_TYPE,
      confirmationSaga.getConfirmationStatus
    ),
    takeEvery(
      confirmationActions.SET_RECORD_CONFIRMATION_STATUS,
      confirmationSaga.maybeConfirmRecord
    ),
    takeEvery(
      confirmationActions.MAYBE_MAKE_DECISION,
      confirmationSaga.maybeMakeDecision
    ),
    takeEvery(
      confirmationActions.SET_CONFIRMING_RECORD,
      viewSaga.stopInitConfirmView
    ),

    // Galleries
    takeEvery(galleryActions.SAVE_GALLERY_IMAGE, gallerySaga.saveGalleryImage),
    takeEvery(
      galleryActions.DELETE_GALLERY_IMAGE,
      gallerySaga.deleteGalleryImage
    ),
    takeEvery(
      galleryActions.CHANGE_GALLERY_IMAGE,
      gallerySaga.changeGalleryImage
    ),

    // Auth process
    takeEvery(authActions.LOGIN, authSaga.login),
    takeEvery(authActions.LOGOUT, authSaga.logout),
    takeEvery(authActions.LOGGED_IN_STATUS_REQUEST, authSaga.checkIsLoggedIn),
    takeEvery(authActions.SET_AUTH_INFO, authSaga.loginRedirectWhenSignedIn),
    takeEvery(authActions.SET_IS_LOGGED_IN, authSaga.loginRedirect),
    takeEvery(authActions.SET_LOGGING_OUT, authSaga.logoutRedirect),

    // Password creation process
    takeEvery(
      createPasswordActions.CHECK_LOGIN_METHODS,
      createPasswordSaga.checkLoginMethods
    ),
    takeEvery(
      createPasswordActions.CREATE_PASSWORD,
      createPasswordSaga.createPassword
    ),

    // Shooter admin view
    takeEvery(adminActions.SAVE_SHOOTER_PROFILE, adminSaga.saveShooterProfile),
    takeEvery(proposalActions.CANCEL_PROPOSAL, proposalSaga.cancelProposal),
    takeEvery(
      adminActions.SAVE_SHOOTER_PROFILE_IMAGE,
      adminSaga.saveShooterProfileImage
    ),
    takeEvery(
      proposalActions.SUBMIT_PROPOSAL_FORM,
      proposalSaga.submitProposalForm
    ),

    // RFP admin view
    takeEvery(proposalActions.CHOOSE_PROPOSAL, proposalSaga.chooseProposal),
    takeEvery(
      feedbackActions.SUBMIT_FEEDBACK_FORM,
      feedbackSaga.submitFeedbackForm
    ),
    takeEvery(requestActions.CLOSE_REQUEST, requestSaga.closeRequest),

    // Questions
    takeEvery(questionActions.ASK_QUESTION, questionSaga.submitNewQuestion),
    takeEvery(questionActions.ANSWER_QUESTION, questionSaga.answerQuestion),

    // Analytics
    takeEvery(formActions.SUBMIT_RFP_FORM, analyticsSaga.createRfpAnalytics),
    takeEvery(
      formActions.SUBMIT_NEW_SHOOTER_FORM,
      analyticsSaga.signupAnalytics
    ),
    takeEvery(
      formActions.SUBMIT_FORTGOT_PASSWORD_FORM,
      analyticsSaga.forgotPasswordAnalytics
    ),
    takeEvery(
      proposalActions.SUBMIT_PROPOSAL_FORM,
      analyticsSaga.submitProposalAnalytics
    ),
    takeEvery(
      proposalActions.CHOOSE_PROPOSAL,
      analyticsSaga.chooseProposalAnalytics
    ),
    takeEvery(
      feedbackActions.SUBMIT_FEEDBACK_FORM,
      analyticsSaga.submitFeedbackAnalytics
    ),
    takeEvery(authActions.LOGIN, analyticsSaga.loginAnalytics),
    takeEvery(authActions.LOGOUT, analyticsSaga.logoutAnalytics),
  ]);
}

const municipalities = [
  { id: '020', name: 'Akaa', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '005',
    name: 'Alajärvi',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '009',
    name: 'Alavieska',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '010',
    name: 'Alavus',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '016',
    name: 'Asikkala',
    provinceId: '07',
    provinceName: 'Päijät-Häme',
  },
  { id: '018', name: 'Askola', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '019',
    name: 'Aura',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '035', name: 'Brändö', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '043', name: 'Eckerö', provinceId: '21', provinceName: 'Ahvenanmaa' },
  {
    id: '046',
    name: 'Enonkoski',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  { id: '047', name: 'Enontekiö', provinceId: '19', provinceName: 'Lappi' },
  { id: '049', name: 'Espoo', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '050', name: 'Eura', provinceId: '04', provinceName: 'Satakunta' },
  { id: '051', name: 'Eurajoki', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '052',
    name: 'Evijärvi',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  { id: '060', name: 'Finström', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '061', name: 'Forssa', provinceId: '05', provinceName: 'Kanta-Häme' },
  { id: '062', name: 'Föglö', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '065', name: 'Geta', provinceId: '21', provinceName: 'Ahvenanmaa' },
  {
    id: '069',
    name: 'Haapajärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '071',
    name: 'Haapavesi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '072',
    name: 'Hailuoto',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '074',
    name: 'Halsua',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  { id: '075', name: 'Hamina', provinceId: '08', provinceName: 'Kymenlaakso' },
  {
    id: '076',
    name: 'Hammarland',
    provinceId: '21',
    provinceName: 'Ahvenanmaa',
  },
  {
    id: '077',
    name: 'Hankasalmi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '078', name: 'Hanko', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '079',
    name: 'Harjavalta',
    provinceId: '04',
    provinceName: 'Satakunta',
  },
  { id: '081', name: 'Hartola', provinceId: '07', provinceName: 'Päijät-Häme' },
  { id: '082', name: 'Hattula', provinceId: '05', provinceName: 'Kanta-Häme' },
  {
    id: '086',
    name: 'Hausjärvi',
    provinceId: '05',
    provinceName: 'Kanta-Häme',
  },
  { id: '111', name: 'Heinola', provinceId: '07', provinceName: 'Päijät-Häme' },
  {
    id: '090',
    name: 'Heinävesi',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  { id: '091', name: 'Helsinki', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '097',
    name: 'Hirvensalmi',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  { id: '098', name: 'Hollola', provinceId: '07', provinceName: 'Päijät-Häme' },
  { id: '099', name: 'Honkajoki', provinceId: '04', provinceName: 'Satakunta' },
  { id: '102', name: 'Huittinen', provinceId: '04', provinceName: 'Satakunta' },
  { id: '103', name: 'Humppila', provinceId: '05', provinceName: 'Kanta-Häme' },
  { id: '105', name: 'Hyrynsalmi', provinceId: '18', provinceName: 'Kainuu' },
  { id: '106', name: 'Hyvinkää', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '108',
    name: 'Hämeenkyrö',
    provinceId: '06',
    provinceName: 'Pirkanmaa',
  },
  {
    id: '109',
    name: 'Hämeenlinna',
    provinceId: '05',
    provinceName: 'Kanta-Häme',
  },
  {
    id: '139',
    name: 'Ii',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '140',
    name: 'Iisalmi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '142', name: 'Iitti', provinceId: '08', provinceName: 'Kymenlaakso' },
  { id: '143', name: 'Ikaalinen', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '145',
    name: 'Ilmajoki',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '146',
    name: 'Ilomantsi',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '153',
    name: 'Imatra',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  { id: '148', name: 'Inari', provinceId: '19', provinceName: 'Lappi' },
  { id: '149', name: 'Inkoo', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '151',
    name: 'Isojoki',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  { id: '152', name: 'Isokyrö', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '165',
    name: 'Janakkala',
    provinceId: '05',
    provinceName: 'Kanta-Häme',
  },
  {
    id: '167',
    name: 'Joensuu',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '169',
    name: 'Jokioinen',
    provinceId: '05',
    provinceName: 'Kanta-Häme',
  },
  { id: '170', name: 'Jomala', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '171', name: 'Joroinen', provinceId: '10', provinceName: 'Etelä-Savo' },
  { id: '172', name: 'Joutsa', provinceId: '13', provinceName: 'Keski-Suomi' },
  {
    id: '174',
    name: 'Juankoski',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '176',
    name: 'Juuka',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '177', name: 'Juupajoki', provinceId: '06', provinceName: 'Pirkanmaa' },
  { id: '178', name: 'Juva', provinceId: '10', provinceName: 'Etelä-Savo' },
  {
    id: '179',
    name: 'Jyväskylä',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '181', name: 'Jämijärvi', provinceId: '04', provinceName: 'Satakunta' },
  { id: '182', name: 'Jämsä', provinceId: '13', provinceName: 'Keski-Suomi' },
  { id: '186', name: 'Järvenpää', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '202',
    name: 'Kaarina',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '204', name: 'Kaavi', provinceId: '11', provinceName: 'Pohjois-Savo' },
  { id: '205', name: 'Kajaani', provinceId: '18', provinceName: 'Kainuu' },
  {
    id: '208',
    name: 'Kalajoki',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '211', name: 'Kangasala', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '213',
    name: 'Kangasniemi',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  {
    id: '214',
    name: 'Kankaanpää',
    provinceId: '04',
    provinceName: 'Satakunta',
  },
  {
    id: '216',
    name: 'Kannonkoski',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  {
    id: '217',
    name: 'Kannus',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '218',
    name: 'Karijoki',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  { id: '224', name: 'Karkkila', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '226',
    name: 'Karstula',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '230', name: 'Karvia', provinceId: '04', provinceName: 'Satakunta' },
  { id: '231', name: 'Kaskinen', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '232',
    name: 'Kauhajoki',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '233',
    name: 'Kauhava',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  { id: '235', name: 'Kauniainen', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '236',
    name: 'Kaustinen',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '239',
    name: 'Keitele',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '240', name: 'Kemi', provinceId: '19', provinceName: 'Lappi' },
  { id: '320', name: 'Kemijärvi', provinceId: '19', provinceName: 'Lappi' },
  { id: '241', name: 'Keminmaa', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '322',
    name: 'Kemiönsaari',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '244',
    name: 'Kempele',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '245', name: 'Kerava', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '249', name: 'Keuruu', provinceId: '13', provinceName: 'Keski-Suomi' },
  { id: '250', name: 'Kihniö', provinceId: '06', provinceName: 'Pirkanmaa' },
  { id: '256', name: 'Kinnula', provinceId: '13', provinceName: 'Keski-Suomi' },
  { id: '257', name: 'Kirkkonummi', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '260',
    name: 'Kitee',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '261', name: 'Kittilä', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '263',
    name: 'Kiuruvesi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '265',
    name: 'Kivijärvi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '271', name: 'Kokemäki', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '272',
    name: 'Kokkola',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  { id: '273', name: 'Kolari', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '275',
    name: 'Konnevesi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  {
    id: '276',
    name: 'Kontiolahti',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '280', name: 'Korsnäs', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '284',
    name: 'Koski Tl',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '285', name: 'Kotka', provinceId: '08', provinceName: 'Kymenlaakso' },
  { id: '286', name: 'Kouvola', provinceId: '08', provinceName: 'Kymenlaakso' },
  {
    id: '287',
    name: 'Kristiinankaupunki',
    provinceId: '15',
    provinceName: 'Pohjanmaa',
  },
  { id: '288', name: 'Kruunupyy', provinceId: '15', provinceName: 'Pohjanmaa' },
  { id: '290', name: 'Kuhmo', provinceId: '18', provinceName: 'Kainuu' },
  {
    id: '291',
    name: 'Kuhmoinen',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '295', name: 'Kumlinge', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '297', name: 'Kuopio', provinceId: '11', provinceName: 'Pohjois-Savo' },
  {
    id: '300',
    name: 'Kuortane',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '301',
    name: 'Kurikka',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '304',
    name: 'Kustavi',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '305',
    name: 'Kuusamo',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '312',
    name: 'Kyyjärvi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '316', name: 'Kärkölä', provinceId: '07', provinceName: 'Päijät-Häme' },
  {
    id: '317',
    name: 'Kärsämäki',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '318', name: 'Kökar', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '398', name: 'Lahti', provinceId: '07', provinceName: 'Päijät-Häme' },
  { id: '399', name: 'Laihia', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '400',
    name: 'Laitila',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '407', name: 'Lapinjärvi', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '402',
    name: 'Lapinlahti',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '403',
    name: 'Lappajärvi',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '405',
    name: 'Lappeenranta',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  {
    id: '408',
    name: 'Lapua',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  { id: '410', name: 'Laukaa', provinceId: '13', provinceName: 'Keski-Suomi' },
  { id: '416', name: 'Lemi', provinceId: '09', provinceName: 'Etelä-Karjala' },
  { id: '417', name: 'Lemland', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '418', name: 'Lempäälä', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '420',
    name: 'Leppävirta',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '421',
    name: 'Lestijärvi',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '422',
    name: 'Lieksa',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '423',
    name: 'Lieto',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '425',
    name: 'Liminka',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '426',
    name: 'Liperi',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '444', name: 'Lohja', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '430',
    name: 'Loimaa',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '433', name: 'Loppi', provinceId: '05', provinceName: 'Kanta-Häme' },
  { id: '434', name: 'Loviisa', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '435', name: 'Luhanka', provinceId: '13', provinceName: 'Keski-Suomi' },
  {
    id: '436',
    name: 'Lumijoki',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '438',
    name: 'Lumparland',
    provinceId: '21',
    provinceName: 'Ahvenanmaa',
  },
  { id: '440', name: 'Luoto', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '441',
    name: 'Luumäki',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  { id: '442', name: 'Luvia', provinceId: '04', provinceName: 'Satakunta' },
  { id: '475', name: 'Maalahti', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '478',
    name: 'Maarianhamina',
    provinceId: '21',
    provinceName: 'Ahvenanmaa',
  },
  {
    id: '480',
    name: 'Marttila',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '481',
    name: 'Masku',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '483',
    name: 'Merijärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '484',
    name: 'Merikarvia',
    provinceId: '04',
    provinceName: 'Satakunta',
  },
  {
    id: '489',
    name: 'Miehikkälä',
    provinceId: '08',
    provinceName: 'Kymenlaakso',
  },
  { id: '491', name: 'Mikkeli', provinceId: '10', provinceName: 'Etelä-Savo' },
  {
    id: '494',
    name: 'Muhos',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '495', name: 'Multia', provinceId: '13', provinceName: 'Keski-Suomi' },
  { id: '498', name: 'Muonio', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '499',
    name: 'Mustasaari',
    provinceId: '15',
    provinceName: 'Pohjanmaa',
  },
  { id: '500', name: 'Muurame', provinceId: '13', provinceName: 'Keski-Suomi' },
  {
    id: '503',
    name: 'Mynämäki',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '504', name: 'Myrskylä', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '505', name: 'Mäntsälä', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '508',
    name: 'Mänttä-Vilppula',
    provinceId: '06',
    provinceName: 'Pirkanmaa',
  },
  {
    id: '507',
    name: 'Mäntyharju',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  {
    id: '529',
    name: 'Naantali',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '531', name: 'Nakkila', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '535',
    name: 'Nivala',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '536', name: 'Nokia', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '538',
    name: 'Nousiainen',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '541',
    name: 'Nurmes',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '543', name: 'Nurmijärvi', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '545', name: 'Närpiö', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '560',
    name: 'Orimattila',
    provinceId: '07',
    provinceName: 'Päijät-Häme',
  },
  {
    id: '561',
    name: 'Oripää',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '562', name: 'Orivesi', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '563',
    name: 'Oulainen',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '564',
    name: 'Oulu',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '309',
    name: 'Outokumpu',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '576',
    name: 'Padasjoki',
    provinceId: '07',
    provinceName: 'Päijät-Häme',
  },
  {
    id: '577',
    name: 'Paimio',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '578', name: 'Paltamo', provinceId: '18', provinceName: 'Kainuu' },
  {
    id: '445',
    name: 'Parainen',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '580',
    name: 'Parikkala',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  { id: '581', name: 'Parkano', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '599',
    name: 'Pedersören kunta',
    provinceId: '15',
    provinceName: 'Pohjanmaa',
  },
  { id: '583', name: 'Pelkosenniemi', provinceId: '19', provinceName: 'Lappi' },
  { id: '854', name: 'Pello', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '584',
    name: 'Perho',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '588',
    name: 'Pertunmaa',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  {
    id: '592',
    name: 'Petäjävesi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  {
    id: '593',
    name: 'Pieksämäki',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  {
    id: '595',
    name: 'Pielavesi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '598',
    name: 'Pietarsaari',
    provinceId: '15',
    provinceName: 'Pohjanmaa',
  },
  {
    id: '601',
    name: 'Pihtipudas',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '604', name: 'Pirkkala', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '607',
    name: 'Polvijärvi',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '608', name: 'Pomarkku', provinceId: '04', provinceName: 'Satakunta' },
  { id: '609', name: 'Pori', provinceId: '04', provinceName: 'Satakunta' },
  { id: '611', name: 'Pornainen', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '638', name: 'Porvoo', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '614', name: 'Posio', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '615',
    name: 'Pudasjärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '616', name: 'Pukkila', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '619',
    name: 'Punkalaidun',
    provinceId: '06',
    provinceName: 'Pirkanmaa',
  },
  { id: '620', name: 'Puolanka', provinceId: '18', provinceName: 'Kainuu' },
  { id: '623', name: 'Puumala', provinceId: '10', provinceName: 'Etelä-Savo' },
  { id: '624', name: 'Pyhtää', provinceId: '08', provinceName: 'Kymenlaakso' },
  {
    id: '625',
    name: 'Pyhäjoki',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '626',
    name: 'Pyhäjärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '630',
    name: 'Pyhäntä',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '631',
    name: 'Pyhäranta',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '635', name: 'Pälkäne', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '636',
    name: 'Pöytyä',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '678',
    name: 'Raahe',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '710', name: 'Raasepori', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '680',
    name: 'Raisio',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '681',
    name: 'Rantasalmi',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  { id: '683', name: 'Ranua', provinceId: '19', provinceName: 'Lappi' },
  { id: '684', name: 'Rauma', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '686',
    name: 'Rautalampi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '687',
    name: 'Rautavaara',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '689',
    name: 'Rautjärvi',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  {
    id: '691',
    name: 'Reisjärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '694',
    name: 'Riihimäki',
    provinceId: '05',
    provinceName: 'Kanta-Häme',
  },
  { id: '697', name: 'Ristijärvi', provinceId: '18', provinceName: 'Kainuu' },
  { id: '698', name: 'Rovaniemi', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '700',
    name: 'Ruokolahti',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  { id: '702', name: 'Ruovesi', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '704',
    name: 'Rusko',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '707',
    name: 'Rääkkylä',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '729',
    name: 'Saarijärvi',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '732', name: 'Salla', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '734',
    name: 'Salo',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '736', name: 'Saltvik', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '790', name: 'Sastamala', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '738',
    name: 'Sauvo',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '739',
    name: 'Savitaipale',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  {
    id: '740',
    name: 'Savonlinna',
    provinceId: '10',
    provinceName: 'Etelä-Savo',
  },
  { id: '742', name: 'Savukoski', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '743',
    name: 'Seinäjoki',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '746',
    name: 'Sievi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '747', name: 'Siikainen', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '748',
    name: 'Siikajoki',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '791',
    name: 'Siikalatva',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '749',
    name: 'Siilinjärvi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '751', name: 'Simo', provinceId: '19', provinceName: 'Lappi' },
  { id: '753', name: 'Sipoo', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '755', name: 'Siuntio', provinceId: '01', provinceName: 'Uusimaa' },
  { id: '758', name: 'Sodankylä', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '759',
    name: 'Soini',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '761',
    name: 'Somero',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '762',
    name: 'Sonkajärvi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '765', name: 'Sotkamo', provinceId: '18', provinceName: 'Kainuu' },
  { id: '766', name: 'Sottunga', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '768', name: 'Sulkava', provinceId: '10', provinceName: 'Etelä-Savo' },
  { id: '771', name: 'Sund', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '777', name: 'Suomussalmi', provinceId: '18', provinceName: 'Kainuu' },
  {
    id: '778',
    name: 'Suonenjoki',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '781', name: 'Sysmä', provinceId: '07', provinceName: 'Päijät-Häme' },
  { id: '783', name: 'Säkylä', provinceId: '04', provinceName: 'Satakunta' },
  {
    id: '831',
    name: 'Taipalsaari',
    provinceId: '09',
    provinceName: 'Etelä-Karjala',
  },
  {
    id: '832',
    name: 'Taivalkoski',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  {
    id: '833',
    name: 'Taivassalo',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  { id: '834', name: 'Tammela', provinceId: '05', provinceName: 'Kanta-Häme' },
  { id: '837', name: 'Tampere', provinceId: '06', provinceName: 'Pirkanmaa' },
  { id: '844', name: 'Tervo', provinceId: '11', provinceName: 'Pohjois-Savo' },
  { id: '845', name: 'Tervola', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '846',
    name: 'Teuva',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '848',
    name: 'Tohmajärvi',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  {
    id: '849',
    name: 'Toholampi',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '850',
    name: 'Toivakka',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  { id: '851', name: 'Tornio', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '853',
    name: 'Turku',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '857',
    name: 'Tuusniemi',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '858', name: 'Tuusula', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '859',
    name: 'Tyrnävä',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '886', name: 'Ulvila', provinceId: '04', provinceName: 'Satakunta' },
  { id: '887', name: 'Urjala', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '889',
    name: 'Utajärvi',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '890', name: 'Utsjoki', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '892',
    name: 'Uurainen',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  {
    id: '893',
    name: 'Uusikaarlepyy',
    provinceId: '15',
    provinceName: 'Pohjanmaa',
  },
  {
    id: '895',
    name: 'Uusikaupunki',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '785',
    name: 'Vaala',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '905', name: 'Vaasa', provinceId: '15', provinceName: 'Pohjanmaa' },
  {
    id: '908',
    name: 'Valkeakoski',
    provinceId: '06',
    provinceName: 'Pirkanmaa',
  },
  {
    id: '911',
    name: 'Valtimo',
    provinceId: '12',
    provinceName: 'Pohjois-Karjala',
  },
  { id: '092', name: 'Vantaa', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '915',
    name: 'Varkaus',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  {
    id: '918',
    name: 'Vehmaa',
    provinceId: '02',
    provinceName: 'Varsinais-Suomi',
  },
  {
    id: '921',
    name: 'Vesanto',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '922', name: 'Vesilahti', provinceId: '06', provinceName: 'Pirkanmaa' },
  {
    id: '924',
    name: 'Veteli',
    provinceId: '16',
    provinceName: 'Keski-Pohjanmaa',
  },
  {
    id: '925',
    name: 'Vieremä',
    provinceId: '11',
    provinceName: 'Pohjois-Savo',
  },
  { id: '927', name: 'Vihti', provinceId: '01', provinceName: 'Uusimaa' },
  {
    id: '931',
    name: 'Viitasaari',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
  {
    id: '934',
    name: 'Vimpeli',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '935',
    name: 'Virolahti',
    provinceId: '08',
    provinceName: 'Kymenlaakso',
  },
  { id: '936', name: 'Virrat', provinceId: '06', provinceName: 'Pirkanmaa' },
  { id: '941', name: 'Vårdö', provinceId: '21', provinceName: 'Ahvenanmaa' },
  { id: '946', name: 'Vöyri', provinceId: '15', provinceName: 'Pohjanmaa' },
  { id: '976', name: 'Ylitornio', provinceId: '19', provinceName: 'Lappi' },
  {
    id: '977',
    name: 'Ylivieska',
    provinceId: '17',
    provinceName: 'Pohjois-Pohjanmaa',
  },
  { id: '980', name: 'Ylöjärvi', provinceId: '06', provinceName: 'Pirkanmaa' },
  { id: '981', name: 'Ypäjä', provinceId: '05', provinceName: 'Kanta-Häme' },
  {
    id: '989',
    name: 'Ähtäri',
    provinceId: '14',
    provinceName: 'Etelä-Pohjanmaa',
  },
  {
    id: '992',
    name: 'Äänekoski',
    provinceId: '13',
    provinceName: 'Keski-Suomi',
  },
];

const locations = municipalities.map((municipality) => municipality.name);
const provinces = Array.from(
  new Set(municipalities.map((municipality) => municipality.provinceName))
).sort();
const municipalityIsInProvince = (municipality, province) =>
  municipalities.find((m) => m.name === municipality).provinceName === province;

module.exports = {
  municipalities,
  locations,
  provinces,
  municipalityIsInProvince,
};

import { put, delay, call, all, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as selectors from '../selectors/selectors';
import * as globals from '../constants/globals';
import * as authActions from '../actions/authActions';
import * as confirmationActions from '../actions/confirmationActions';
import * as createPasswordActions from '../actions/createPasswordActions';
import * as formActions from '../actions/formActions';
import * as viewActions from '../actions/viewActions';
import * as adminSaga from './adminSaga';
import * as authSaga from './authSaga';
import * as feedbackSaga from './feedbackSaga';
import * as gallerySaga from './gallerySaga';
import * as questionSaga from './questionSaga';
import * as shootersSaga from './shootersSaga';
import * as surveySaga from './surveySaga';

/**
 * Initializes a 'form' view, such as home or join view. Sets up the state
 * for successful form entry.
 *
 * @name initFormView
 * @function
 */
function* initFormView(pathname) {
  yield put(viewActions.setInitializingView(true));
  yield all([
    put(formActions.setQuestionsForView(pathname)),
    put(formActions.setFormOpen(false)),
    put(formActions.setQuestionFocus(false)),
    put(formActions.setQuestionAnswered(false)),
    put(formActions.setFormSubmitResult('')),
    put(formActions.setSubmittingForm(false)),
    put(formActions.setFormReadyToOpen(false)),
    put(formActions.setCurrentQuestionIndex(0)),
    put(formActions.setFormType(pathname)),
    put(formActions.setFormError('')),
  ]);
  yield put(viewActions.setInitializingView(false));
  yield delay(1000);
  yield put(formActions.setFormExited(true));
}

/**
 * Initializes the confirmation view. Dispatches the setting of the
 * confirmation record to the store, which in turn sets off a number
 * of side effects. If the init view request also contains a decision
 * whether to accept or deny a shooter, a note of this is also made to
 * the store. This will be checked in the confirmation saga.
 *
 * Later, when the snowball has finished rolling, the
 * stopInitConfirmView function is launched from the rootSaga, which
 * ends the process.
 *
 * @name initConfirmView
 * @function
 * @param {object} payload Object containing either a record ID to confirm
 *                         or a decision outcome and a shooter ID for accepting
 *                         or denying entry to the shooter roster.
 */
function* initConfirmView(payload) {
  yield put(showLoading());
  yield put(viewActions.setInitializingView(true));
  yield put(confirmationActions.setShowConfirmation(true));
  yield delay(1000);
  const id = payload.recordId || payload.shooterId;
  if (payload.decision) {
    yield put(confirmationActions.setDecisionToMakeAfterInit(payload.decision));
  }
  yield put(confirmationActions.setConfirmationRecordId(id));
}

/**
 * Stops view initialization for the confirm view. Called by the
 * rootSaga when the confirmation process is complete. If there
 * is an accept/deny decision marked to be made in the store, that
 * is made as well.
 *
 * @name stopInitConfirmView
 * @function
 * @param {boolean} payload The payload of SET_CONFIRMING_RECORD, which is
 *                          run in every case when the process finishes.
 */
export function* stopInitConfirmView({ payload }) {
  if (payload !== false) return;
  yield put(hideLoading());
  yield put(confirmationActions.setShowConfirmation(false));
  yield delay(1000);
  yield put(viewActions.setInitializingView(false));
  yield put(confirmationActions.maybeMakeDecision());
  yield put(confirmationActions.setShowConfirmation(true));
}

/**
 * Initializes the login view. A check whether the user is already logged in
 * is made. The rest is handled by the rootSaga.
 *
 * @name initLoginView
 * @function
 */
function* initLoginView() {
  yield put(viewActions.setInitializingView(true));
  yield put(authActions.loggedInStatusRequest());
  yield put(viewActions.setInitializingView(false));
}

/**
 * Inits the create password view. Stores the would-be-shooter's ID
 * into state and checks login methods, from where things are taken
 * over by rootSaga.
 *
 * @name initCreatePasswordView
 * @function
 * @param {object} payload INIT_VIEW info object.
 */
function* initCreatePasswordView(payload) {
  yield put(viewActions.setInitializingView(true));
  yield put(createPasswordActions.setPasswordCreatorId(payload.shooterId));
  yield put(createPasswordActions.checkLoginMethods());
  yield put(viewActions.setInitializingView(false));
}

/**
 * Inits the shooter dashboard view. Checks if the user is logged in.
 * Fetches all the required data from the API for the view.
 *
 * @name initDashboardView
 * @function
 */
export function* initDashboardView() {
  yield put(viewActions.setInitializingView(true));
  const isLoggedIn = yield call(authSaga.redirectToLoginIfNotLoggedIn);
  if (!isLoggedIn) {
    return;
  }
  const isShooter = yield call(
    authSaga.checkUserTypeAndMaybeRedirect,
    globals.TYPE_SHOOTER
  );
  if (!isShooter) {
    return;
  }
  yield all([
    call(adminSaga.fetchProposalsOfLoggedInShooter),
    call(adminSaga.fetchRequests),
    call(adminSaga.fetchShooter),
    call(feedbackSaga.fetchShooterFeedback),
    call(gallerySaga.fetchGallery),
    call(questionSaga.fetchQuestions),
  ]);
  const isAdmin = yield select(selectors.isAdmin);
  if (isAdmin) {
    yield all([
      call(adminSaga.fetchRequests),
      call(adminSaga.fetchShootersAll),
      call(adminSaga.fetchProposalsAll),
      call(feedbackSaga.fetchFeedback),
    ]);
  }
  yield put(viewActions.setInitializingView(false));
}

/**
 * Inits the RFP admin view. Fetches all the required data
 * from the API for the view.
 *
 * @name initRFPAdminView
 * @function
 * @param {string} rfpId Request for proposal ID.
 */
export function* initRFPAdminView() {
  yield put(viewActions.setInitializingView(true));
  const isLoggedIn = yield call(authSaga.redirectToLoginIfNotLoggedIn);
  if (!isLoggedIn) {
    return;
  }
  const isRFP = yield call(
    authSaga.checkUserTypeAndMaybeRedirect,
    globals.TYPE_RFP
  );
  if (!isRFP) {
    return;
  }
  yield all([
    call(adminSaga.fetchProposalsOfRfp),
    call(adminSaga.fetchRequest),
    call(adminSaga.fetchShootersOfRfp),
    call(questionSaga.fetchQuestions),
  ]);
  yield all([
    call(feedbackSaga.fetchFeedback),
    call(gallerySaga.fetchGalleries),
  ]);
  yield put(viewActions.setInitializingView(false));
}

/**
 * Initializes the survey view.
 *
 * @name initSurveyView
 * @function
 */
function* initSurveyView(payload) {
  console.log(payload);
  yield put(showLoading());
  yield put(viewActions.setInitializingView(true));
  yield call(surveySaga.submitSurvey, payload);
  yield put(viewActions.setInitializingView(false));
  yield put(hideLoading());
}

/**
 * Initializes the shooters view.
 *
 * @name initShootersView
 * @function
 */
function* initShootersListView() {
  yield put(viewActions.setInitializingView(true));
  yield call(shootersSaga.getShootersListInfo);
  yield put(viewActions.setInitializingView(false));
}

/**
 * Initializes a view. Called by root saga when a page / pathname is first
 * mounted.
 *
 * @name initView
 * @function
 * @param {string} pathname The view to initialize
 * @param {object} payload  Potential extras, such as user ID.
 */
export function* initView({ pathname, payload }) {
  if (pathname !== globals.PATHNAME_SURVEY) {
    yield call(authSaga.checkIsLoggedIn);
  }
  switch (pathname) {
    case globals.PATHNAME_HOME: {
      yield initFormView(pathname);
      break;
    }
    case globals.PATHNAME_JOIN: {
      yield initFormView(pathname);
      break;
    }
    case globals.PATHNAME_FORGOT: {
      yield initFormView(pathname);
      break;
    }
    case globals.PATHNAME_CONFIRM: {
      yield initConfirmView(payload);
      break;
    }
    case globals.PATHNAME_LOGIN: {
      yield initFormView(pathname);
      yield initLoginView();
      break;
    }
    case globals.PATHNAME_CREATE_PASSWORD: {
      yield initFormView(pathname);
      yield initCreatePasswordView(payload);
      break;
    }
    case globals.PATHNAME_DASHBOARD: {
      yield initDashboardView();
      break;
    }
    case globals.PATHNAME_PROPOSALS: {
      yield initRFPAdminView();
      break;
    }
    case globals.PATHNAME_SURVEY: {
      yield initSurveyView(payload);
      break;
    }
    case globals.PATHNAME_SHOOTERS_LIST: {
      yield initShootersListView();
      break;
    }
    default:
  }
}

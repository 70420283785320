import React, { useEffect } from 'react';
import Router from 'next/router';
import Head from 'next/head';
import { wrapper } from '../store';
import { trackPageView } from '../helpers/helperFunctions';
import '../styles/main.scss';

// eslint-disable-next-line
function App({ Component, pageProps }) {
  useEffect(() => {
    // Track pageviews
    Router.onRouteChangeComplete = (url) => {
      trackPageView(url);
    };
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width"
          key="viewport"
        />
        <meta charSet="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/favicon/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default wrapper.withRedux(App);

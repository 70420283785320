import { put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import sagaError from './sagaError';
import * as globals from '../constants/globals';
import * as shootersListActions from '../actions/shootersListActions';

/**
 * Submits a survey response.
 *
 * @name fetchShootersInfo
 * @function
 * @param {boolean} {payload} Survey answer and rfp id.
 */
export function* fetchShootersListInfo() {
  try {
    const response = yield fetch(globals.SHOOTERS_LIST_URL, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const error = yield response.json();
      throw new Error(error);
    }
    return response;
  } catch (error) {
    return sagaError(error);
  }
}

export function* getShootersListInfo() {
  try {
    yield put(showLoading());
    const response = yield fetchShootersListInfo();
    const result = yield response.json();
    yield put(shootersListActions.setShootersList(result));
    yield put(hideLoading());
  } catch (error) {
    sagaError(error);
  }
}

import DOMPurify from 'dompurify';
import validator from 'validator';
import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import { locations } from '../helpers/locations';
import { capitalizeFirstLetter } from '../helpers/helperFunctions';
import * as formActions from '../actions/formActions';
import * as globals from '../constants/globals';
import * as questions from '../constants/questions';
import * as selectors from '../selectors/selectors';
import sagaError from './sagaError';

const { FinnishBusinessIds } = require('finnish-business-ids');

/**
 * Fetches email availability from API
 *
 * @name doCheckEmailAvailability
 * @function
 * @param   {string} email Email address to check
 * @returns {fetch}        Fetch
 */
function* doCheckEmailAvailability(email) {
  return yield fetch(`${globals.EMAIL_CHECK_URL}/${email}`, {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'same-origin',
  });
}

/**
 * Validates each answer upon submitting it
 *
 * @name validateAnswer
 * @function
 */
export default function* validateAnswer() {
  try {
    let currentAnswer = yield select(selectors.currentAnswer);
    const currentQuestion = yield select(selectors.currentQuestion);
    const formType = yield select(selectors.formType);
    const { tag, slug } = currentQuestion;

    // Handle checkboxes and radio buttons.
    if (tag === 'fieldset' && !currentAnswer.length) {
      yield put(formActions.setFormError(globals.INVALID_NONSELECTED));
      return;
    }
    if (tag === 'fieldset') {
      yield put(formActions.setFormError(''));
      return;
    }

    // Handle other questions.
    validator.trim(currentAnswer);
    currentAnswer = DOMPurify.sanitize(currentAnswer);

    if (currentAnswer === '') {
      yield put(formActions.setFormError(globals.INVALID_EMPTY));
      return;
    }

    // QUESTION TYPE VALIDATIONS
    if (slug === questions.QUESTION_DATE) {
      // Parse input
      const regex = new RegExp(/\d{2}(\.|-)\d{2}(\.|-)\d{4}/); // eslint-disable-line
      const result = currentAnswer.match(regex);
      if (result === null) {
        yield put(formActions.setFormError(globals.INVALID_MALFORMED_DATE));
        return;
      }
      if (
        moment(currentAnswer, 'DD.MM.YYYY').isAfter(moment().add(3, 'years'))
      ) {
        yield put(formActions.setFormError(globals.INVALID_TOO_LATE));
        return;
      }
      if (moment(currentAnswer, 'DD.MM.YYYY').isBefore(moment())) {
        yield put(formActions.setFormError(globals.INVALID_IN_PAST));
        return;
      }
      if (
        moment(currentAnswer, 'DD.MM.YYYY').isBefore(moment().add(7, 'days'))
      ) {
        yield put(formActions.setFormError(globals.INVALID_TOO_SOON));
        return;
      }
    }

    if (slug === questions.QUESTION_LOCATION) {
      if (!locations.includes(capitalizeFirstLetter(currentAnswer))) {
        yield put(formActions.setFormError(globals.INVALID_LOCATION));
        return;
      }
    }

    if (slug === questions.QUESTION_NAMES) {
      const regex = new RegExp(/( ja | & | sekä )/); // eslint-disable-line
      const result = currentAnswer.replace('&amp;', '&').match(regex);
      if (result === null) {
        yield put(formActions.setFormError(globals.INVALID_NAMES));
        return;
      }
    }

    if (slug === questions.QUESTION_ORGCODE) {
      const validId = FinnishBusinessIds.isValidBusinessId(currentAnswer);
      if (validId !== true) {
        yield put(formActions.setFormError(globals.INVALID_ORGCODE));
        return;
      }
    }

    if (slug === questions.QUESTION_WWW) {
      const validId = validator.isURL(currentAnswer, {
        require_protocol: true,
      });
      if (validId !== true) {
        yield put(formActions.setFormError(globals.INVALID_WWW));
        return;
      }
    }

    if (slug === questions.QUESTION_EMAIL) {
      const isValidEmail = validator.isEmail(currentAnswer);
      if (!isValidEmail) {
        yield put(formActions.setFormError(globals.INVALID_EMAIL_INVALID));
        return;
      }
      const prankster =
        currentAnswer.indexOf('+') >= 0 &&
        currentAnswer.indexOf('nmyllynen') === -1;
      if (prankster) {
        yield put(formActions.setFormError(globals.INVALID_EMAIL_INVALID));
        return;
      }
      if (
        formType !== globals.PATHNAME_LOGIN &&
        formType !== globals.PATHNAME_FORGOT
      ) {
        const emailIsAvailable = yield doCheckEmailAvailability(
          encodeURI(currentAnswer)
        );
        if (!emailIsAvailable.ok) {
          const status = yield emailIsAvailable.json();
          yield put(formActions.setFormError(status.message));
          return;
        }
      }
    }

    if (
      slug === questions.QUESTION_PASSWORD_CONFIRM ||
      slug === questions.QUESTION_PASSWORD
    ) {
      if (
        currentAnswer.length < 3 ||
        (currentAnswer.length > 4 && currentAnswer.length < 8)
      ) {
        yield put(formActions.setFormError(globals.INVALID_PASSWORD_TOO_SHORT));
        return;
      }
    }

    if (slug === questions.QUESTION_PASSWORD_CONFIRM) {
      const passwordAnswer = yield select(
        selectors.answer,
        questions.QUESTION_PASSWORD
      );
      if (currentAnswer !== passwordAnswer) {
        yield put(
          formActions.setFormError(globals.INVALID_PASSWORDS_DONT_MATCH)
        );
        return;
      }
    }
    // If there is no error clear it.
    yield put(formActions.setFormError(''));
  } catch (error) {
    sagaError(error);
  }
}

import * as actions from '../actions/viewActions';

/*
 * Action handlers
 */
const ACTION_HANDLERS = {
  [actions.SET_INITIALIZING_VIEW]: (state, action) => ({
    ...state,
    initializingView: action.payload,
  }),
};

/*
 * Reducer
 */
const initialState = {
  initializingView: true,
};

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import * as adminActions from '../actions/adminActions';
import * as galleryActions from '../actions/galleryActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [adminActions.SET_FETCHING_REQUESTS]: (state, action) => ({
    ...state,
    fetchingRequests: action.payload,
  }),

  [galleryActions.SET_FETCHING_GALLERY]: (state, action) => ({
    ...state,
    fetchingGalleries: action.payload,
  }),

  [adminActions.SET_FETCHING_PROPOSALS]: (state, action) => ({
    ...state,
    fetchingProposals: action.payload,
  }),

  [adminActions.SET_FETCHING_SHOOTERS]: (state, action) => ({
    ...state,
    fetchingShooters: action.payload,
  }),

  [adminActions.SET_SAVING_PROFILE]: (state, action) => ({
    ...state,
    savingProfile: action.payload,
  }),

  [adminActions.SET_SUBMITTING_PROPOSAL]: (state, action) => ({
    ...state,
    submittingProposal: action.payload,
  }),

  [adminActions.SET_SUBMITTING_FEEDBACK]: (state, action) => ({
    ...state,
    submittingFeedback: action.payload,
  }),

  [adminActions.SET_SUBMITTING_QUESTION]: (state, action) => ({
    ...state,
    submittingQuestion: action.payload,
  }),

  [adminActions.SET_CHOOSING_PROPOSAL]: (state, action) => ({
    ...state,
    choosingProposal: action.payload,
  }),

  [adminActions.SET_CANCELING_PROPOSAL]: (state, action) => ({
    ...state,
    cancelingProposal: action.payload,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  // fetchingProposals: true,
  // fetchingRequests: true,
  // fetchingShooters: true,
  // fetchingGalleries: true,
  choosingProposal: false,
  savingProfile: false,
  submittingProposal: false,
  submittingFeedback: false,
  submittingQuestion: false,
};

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

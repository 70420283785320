/* eslint no-param-reassign: 0 */
import axios from 'axios';
import { put, select, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as adminActions from '../actions/adminActions';
import * as galleryActions from '../actions/galleryActions';
import * as selectors from '../selectors/selectors';
import * as globals from '../constants/globals';
import sagaError from './sagaError';

function* doSaveGalleryImage(payload) {
  return yield axios.post(globals.GALLERY_URL, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function* doChangeGalleryImage(payload) {
  return yield axios.post(globals.GALLERY_URL, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * Delete a gallery image.
 *
 * @name doDeleteGalleryImage
 * @function
 * @param   {string}  payload Image ID
 * @returns {Promise}         Axios response
 */
function* doDeleteGalleryImage(payload) {
  return yield axios.delete(`${globals.GALLERY_URL}/${payload}`);
}

function* doFetchGallery(shooterId = undefined) {
  if (!shooterId) {
    shooterId = yield select(selectors.loggedInShooterId);
  }
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    mode: 'cors',
    redirect: 'follow',
  };
  return yield fetch(`${globals.GALLERY_URL}/${shooterId}/`, request);
}

export function* fetchGallery(shooterId = undefined) {
  try {
    yield put(galleryActions.setFetchingGallery(true));
    if (!shooterId) {
      shooterId = yield select(selectors.loggedInShooterId);
    }
    const response = yield doFetchGallery(shooterId);
    if (!response.ok) {
      const error = yield response.text();
      throw new Error(error);
    }
    const result = yield response.json();
    yield put(galleryActions.setGallery(shooterId, result));
  } catch (error) {
    sagaError(error, 'Gallerian haku ei onnistunut');
  } finally {
    yield put(galleryActions.setFetchingGallery(false));
  }
}

export function* saveGalleryImage({ payload }) {
  yield put(adminActions.setSavingProfile(true));
  yield put(showLoading());
  try {
    const response = yield doSaveGalleryImage(payload);
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield fetchGallery();
    toast.success('Kuva tallennettu');
  } catch (error) {
    sagaError(error, 'Tallennus ei onnistunut');
  } finally {
    yield put(adminActions.setSavingProfile(false));
    yield put(hideLoading());
  }
}

export function* changeGalleryImage({ payload }) {
  yield put(adminActions.setSavingProfile(true));
  yield put(showLoading());
  try {
    const response = yield doChangeGalleryImage(payload);
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield fetchGallery();
    toast.success('Kuva vaihdettu');
  } catch (error) {
    sagaError(error, 'Tallennus ei onnistunut');
  } finally {
    yield put(adminActions.setSavingProfile(false));
    yield put(hideLoading());
  }
}

export function* deleteGalleryImage({ payload }) {
  yield put(adminActions.setSavingProfile(true));
  yield put(showLoading());
  try {
    const response = yield doDeleteGalleryImage(payload);
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield fetchGallery();
    toast.success('Kuva poistettu');
  } catch (error) {
    sagaError(error, 'Poistaminen ei onnistunut');
  } finally {
    yield put(adminActions.setSavingProfile(false));
    yield put(hideLoading());
  }
}

export function* fetchGalleries() {
  try {
    const shooterIds = yield select(selectors.shooterIds);
    const shooterGalleryFetches = shooterIds.map((id) =>
      call(fetchGallery, id)
    );
    yield all(shooterGalleryFetches);
  } catch (error) {
    sagaError(error);
  }
}

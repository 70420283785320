/* eslint no-param-reassign: 0 */
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, call, all, select } from 'redux-saga/effects';
import moment from 'moment';
import * as globals from '../constants/globals';
import * as adminSaga from './adminSaga';
import * as adminActions from '../actions/adminActions';
import * as feedbackActions from '../actions/feedbackActions';
import * as selectors from '../selectors/selectors';
import sagaError from './sagaError';

function* doFetchFeedback(shooterId = undefined) {
  if (!shooterId) {
    shooterId = yield select(selectors.loggedInShooterId);
  }
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    redirect: 'follow',
  };
  return yield fetch(`${globals.FEEDBACK_URL}/${shooterId}/`, request);
}

export const createNewFeedback = (data) => {
  moment.locale('fi');
  const feedback = {
    ...data,
    creadtedOn: moment().format(),
    recommendation: !!Number.parseInt(data.recommendation, 10),
  };
  // Make the call
  const body = JSON.stringify(feedback);
  const request = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(globals.FEEDBACK_URL, request);
};

export function* fetchShooterFeedback(shooterId = undefined) {
  try {
    yield put(feedbackActions.setFetchingFeedback(true));
    if (!shooterId) {
      shooterId = yield select(selectors.loggedInShooterId);
    }
    const response = yield doFetchFeedback(shooterId);
    if (!response.ok) {
      const error = yield response.text();
      throw new Error(error);
    }
    const result = yield response.json();
    yield put(feedbackActions.setFeedback(shooterId, result));
  } catch (error) {
    sagaError(error, 'Palautteen haku ei onnistunut');
  } finally {
    yield put(feedbackActions.setFetchingFeedback(false));
  }
}

/**
 * Fetch feedback for all shooters in store
 *
 * @name fetchFeedback
 * @function
 */
export function* fetchFeedback() {
  try {
    const shooterIds = yield select(selectors.shooterIds);
    const feedbackFetches = shooterIds.map((id) =>
      call(fetchShooterFeedback, id)
    );
    yield all(feedbackFetches);
  } catch (error) {
    sagaError(error);
  }
}

export function* submitFeedbackForm({ payload }) {
  try {
    yield put(adminActions.setSubmittingFeedback(true));
    yield put(showLoading());
    const response = yield createNewFeedback(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(adminSaga.fetchRequest);
    yield call(fetchFeedback);
    toast.success('Palaute jätetty');
  } catch (error) {
    sagaError(error, 'Palautteen jättäminen ei onnistunut');
  } finally {
    yield put(adminActions.setSubmittingFeedback(false));
    yield put(hideLoading());
  }
}

export const SET_PROPOSALS = 'SET_PROPOSALS';
export const CHOOSE_PROPOSAL = 'CHOOSE_PROPOSAL';
export const CANCEL_PROPOSAL = 'CANCEL_PROPOSAL';
export const SUBMIT_PROPOSAL_FORM = 'SUBMIT_PROPOSAL_FORM';

export const setProposals = (payload) => ({
  type: SET_PROPOSALS,
  payload,
});

export const chooseProposal = (payload) => ({
  type: CHOOSE_PROPOSAL,
  payload,
});

export const cancelProposal = (payload) => ({
  type: CANCEL_PROPOSAL,
  payload,
});

export const submitProposalForm = (payload) => ({
  type: SUBMIT_PROPOSAL_FORM,
  payload,
});

import * as actions from '../actions/feedbackActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.SET_FEEDBACK]: (state, action) => ({
    ...state,
    [action.shooterId]: action.feedback,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

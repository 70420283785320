const punycode = require('punycode');

const PORT = process.env.PORT || 3000;
const PUNY_APP_URL = punycode.toASCII(process.env.NEXT_PUBLIC_APP_URL);
const ADMIN_EMAIL = process.env.ADMIN_EMAIL || 'nmyllynen@gmail.com';
const BCC_EMAIL = 'info+bcc@haavalokuvaajat.fi';

// Photo types
const photoTypes = [
  ['Häihin valmistautuminen', 'prep'],
  ['Vihkiseremonia', 'ceremony'],
  ['Potretit', 'portraits'],
  ['Hääjuhla', 'party'],
];

const RFP_SUBMIT_DEADLINE_BEFORE_WEDDING_DAYS = 7;
const RFP_EARLIEST_CLOSE_TIME_DAYS = 20;
const PERIODIC_REMINDER_INTERVAL_DAYS = 5;
const WWW_CHECK_INTERVAL_DAYS = 1;

const PROVISION = process.env.PROVISION || 0.1;
const VAT = process.env.VAT || 0.24;

const DEFAULT_PROFILE_IMAGE_URL = '';
const DEFAULT_SHOOTER_DESCRIPTION = '';
const DEFAULT_SHOOTER_EXPERIENCE = '';
const FROM_EMAIL = '"Häävalokuvaajat.fi" <noreply@haavalokuvaajat.fi>';
const PARAM_START = 's';
const CONFIRM_URL = `${PUNY_APP_URL}/vahvista-tarjouspyynto`;
const DECISION_ACCEPT = 'ok';
const DECISION_DENY = 'nope';
const ACCEPT_URL = `${PUNY_APP_URL}/hyvaksy-hakemus`;
const DENY_URL = `${PUNY_APP_URL}/hylkaa-hakemus`;

// Record types
const TYPE_RFP = 'TYPE_RFP';
const TYPE_SHOOTER = 'TYPE_SHOOTER';
const TYPE_RESET = 'TYPE_RESET';

// Email check responses
const INVALID_EMAIL_HAS_RFP_OPEN = 'INVALID_EMAIL_HAS_RFP_OPEN';
const INVALID_EMAIL_TAKEN_FOR_SHOOTER = 'INVALID_EMAIL_TAKEN_FOR_SHOOTER';

// Login methods
const LOGIN_METHOD_USERNAME_PASSWORD = 'LOGIN_METHOD_USERNAME_PASSWORD';

// Email image urls
const EMAIL_IMG_LOGIN =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/login_text.png';
const EMAIL_IMG_CHECK_FEEDBACK =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/check_feedback_text.png';
const EMAIL_IMG_LEAVE_FEEDBACK =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/leave_feedback_text.png';
const EMAIL_IMG_URL_HEADER =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/header_rings.jpg';
const EMAIL_IMG_CONFIRM_RFP =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/confirm_rfp_text.png';
const EMAIL_IMG_CONFIRM_APPLICATION =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/confirm_application_text.png';
const EMAIL_IMG_ACCEPT_APPLICATION =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/accept_application_text.png';
const EMAIL_IMG_BROWSE_PROPOSALS =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/browse_proposals_text.png';
const EMAIL_IMG_MAKE_OFFER =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/make_offer_text.png';
const EMAIL_IMG_BROWSE_RFPS =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/browse_rfps_text.png';
const EMAIL_IMG_EDIT_PROFILE =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/edit_profile_text.png';
const EMAIL_IMG_DENY_APPLICATION =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/deny_application_text.png';
const EMAIL_IMG_FORMAT_PASSWORD =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/format_password_text.png';
const EMAIL_IMG_CREATE_NEW_PASSWORD =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/create_new_password_text.png';
const EMAIL_IMG_SEE_OFFER =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/see_offer_text.png';
const EMAIL_IMG_SEE_OFFER_SINGULAR =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/see_offer_singular_text.png';
const EMAIL_IMG_SURVEY_100 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_010.png';
const EMAIL_IMG_SURVEY_200 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_020.png';
const EMAIL_IMG_SURVEY_300 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_030.png';
const EMAIL_IMG_SURVEY_400 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_040.png';
const EMAIL_IMG_SURVEY_500 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_050.png';
const EMAIL_IMG_SURVEY_600 =
  'https://s3.eu-north-1.amazonaws.com/wpg-files/email/survey_060.png';

// RFP notification filters
const LOCATION_FILTER_OFF = 'LOCATION_FILTER_OFF';
const LOCATION_FILTER_WHITELIST = 'LOCATION_FILTER_WHITELIST';
const LOCATION_FILTER_BLACKLIST = 'LOCATION_FILTER_BLACKLIST';
const BUDGET_FILTER_OFF = 'BUDGET_FILTER_OFF';
const BUDGET_FILTER_OVER = 'BUDGET_FILTER_OVER';
const BUDGET_FILTER_UNDER = 'BUDGET_FILTER_UNDER';

// Budgets
const BUDGET_100 = 'BUDGET_100';
const BUDGET_200 = 'BUDGET_200';
const BUDGET_300 = 'BUDGET_300';
const BUDGET_400 = 'BUDGET_400';
const BUDGET_500 = 'BUDGET_500';
const BUDGET_600 = 'BUDGET_600';
const BUDGET_NA = 'BUDGET_NA';

// Budgets
const BUDGETS = [
  ['Alle 250 €', BUDGET_100, 100],
  ['250 € - 500 €', BUDGET_200, 200],
  ['500 € - 1000 €', BUDGET_300, 300],
  ['1000 € - 1500 €', BUDGET_400, 400],
  ['1500 € - 2000 €', BUDGET_500, 500],
  ['Yli 2000 €', BUDGET_600, 600],
  ['Emme tiedä', BUDGET_NA, 0],
];

// Proposal acceptance affirmers
const AFFIRMER_CUSTOMER = 'AFFIRMER_CUSTOMER';
const AFFIRMER_SHOOTER = 'AFFIRMER_SHOOTER';

module.exports = {
  ACCEPT_URL,
  ADMIN_EMAIL,
  AFFIRMER_CUSTOMER,
  AFFIRMER_SHOOTER,
  BCC_EMAIL,
  BUDGETS,
  BUDGET_FILTER_OFF,
  BUDGET_FILTER_OVER,
  BUDGET_FILTER_UNDER,
  BUDGET_NA,
  CONFIRM_URL,
  DECISION_ACCEPT,
  DECISION_DENY,
  DEFAULT_PROFILE_IMAGE_URL,
  DEFAULT_SHOOTER_DESCRIPTION,
  DEFAULT_SHOOTER_EXPERIENCE,
  DENY_URL,
  EMAIL_IMG_ACCEPT_APPLICATION,
  EMAIL_IMG_BROWSE_PROPOSALS,
  EMAIL_IMG_BROWSE_RFPS,
  EMAIL_IMG_CHECK_FEEDBACK,
  EMAIL_IMG_CONFIRM_APPLICATION,
  EMAIL_IMG_CONFIRM_RFP,
  EMAIL_IMG_CREATE_NEW_PASSWORD,
  EMAIL_IMG_DENY_APPLICATION,
  EMAIL_IMG_EDIT_PROFILE,
  EMAIL_IMG_FORMAT_PASSWORD,
  EMAIL_IMG_LEAVE_FEEDBACK,
  EMAIL_IMG_LOGIN,
  EMAIL_IMG_MAKE_OFFER,
  EMAIL_IMG_SEE_OFFER,
  EMAIL_IMG_SEE_OFFER_SINGULAR,
  EMAIL_IMG_SURVEY_100,
  EMAIL_IMG_SURVEY_200,
  EMAIL_IMG_SURVEY_300,
  EMAIL_IMG_SURVEY_400,
  EMAIL_IMG_SURVEY_500,
  EMAIL_IMG_SURVEY_600,
  EMAIL_IMG_URL_HEADER,
  FROM_EMAIL,
  INVALID_EMAIL_HAS_RFP_OPEN,
  INVALID_EMAIL_TAKEN_FOR_SHOOTER,
  LOCATION_FILTER_BLACKLIST,
  LOCATION_FILTER_OFF,
  LOCATION_FILTER_WHITELIST,
  LOGIN_METHOD_USERNAME_PASSWORD,
  PARAM_START,
  PERIODIC_REMINDER_INTERVAL_DAYS,
  PORT,
  PROVISION,
  PUNY_APP_URL,
  RFP_SUBMIT_DEADLINE_BEFORE_WEDDING_DAYS,
  RFP_EARLIEST_CLOSE_TIME_DAYS,
  TYPE_RESET,
  TYPE_RFP,
  TYPE_SHOOTER,
  VAT,
  WWW_CHECK_INTERVAL_DAYS,
  photoTypes,
};

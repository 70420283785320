import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import * as confirmationActions from '../actions/confirmationActions';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';
import sagaError from './sagaError';

/**
 * Fetches the type of a record from the type check API.
 *
 * @name fetchType
 * @function
 * @returns {string} The string type description
 */
function* fetchType() {
  try {
    const recordId = yield select(selectors.recordId);
    const response = yield fetch(
      `${globals.RECORD_TYPE_CHECK_URL}/${recordId}`
    );
    if (!response.ok) {
      const error = yield response.text();
      throw new Error(error);
    }
    return yield response.text();
  } catch (error) {
    return sagaError(error);
  }
}

/**
 * Fetches a record from the API. The record can be either an RFP or a Shooter.
 * Returns the resulting json.
 *
 * @name fetchRecord
 * @function
 * @returns {object} The API response
 */
function* fetchRecord() {
  try {
    const recordId = yield select(selectors.recordId);
    const type = yield select(selectors.recordType);
    let recordUrl;
    if (type === globals.TYPE_RFP) {
      recordUrl = globals.RFPS_URL;
    } else if (type === globals.TYPE_SHOOTER) {
      recordUrl = globals.SHOOTERS_URL;
    } else if (type === globals.TYPE_RESET) {
      recordUrl = globals.FORGOT_URL;
    } else {
      throw new Error('Unknown record type');
    }
    const response = yield fetch(`${recordUrl}/conf/${recordId}`);
    if (!response.ok) {
      const error = yield response.json();
      throw new Error(error);
    }
    return yield response.json();
  } catch (error) {
    return sagaError(error);
  }
}

/**
 * Makes a PUT request to the shooterAPI if there is a decision pending
 * in the state. Either sets the shooter as accepted or as denied.
 * Returns the response in its entirety.
 *
 * @name maybeMakeDecision
 * @function
 * @returns {object} The response JSON from the server
 */
export function* maybeMakeDecision() {
  try {
    const decision = yield select(selectors.decision);
    if (!decision) return;
    const shooterId = yield select(selectors.recordId);
    moment.locale('fi');
    const now = moment().format();
    let body;
    if (decision === globals.DECISION_ACCEPT) {
      body = JSON.stringify({
        accepted: true,
        acceptedOn: now,
      });
    }
    if (decision === globals.DECISION_DENY) {
      body = JSON.stringify({
        denied: true,
        deniedOn: now,
      });
    }
    const response = yield fetch(`${globals.SHOOTERS_URL}/conf/${shooterId}`, {
      body,
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield put(confirmationActions.setDecisionResult(result));
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Marks a record as confirmed by PUTting the corresponding API of the record.
 * Returns the record from the API.
 *
 * @name confirmRecord
 * @function
 * @returns {object} The response JSON from the server
 */
function* confirmRecord() {
  try {
    const recordId = yield select(selectors.recordId);
    const type = yield select(selectors.recordType);
    moment.locale('fi');
    const now = moment().format();
    const body = JSON.stringify({
      confirmed: true,
      confirmedOn: now,
    });
    const recordUrl =
      type === globals.TYPE_RFP ? globals.RFPS_URL : globals.SHOOTERS_URL;
    const response = yield fetch(`${recordUrl}/conf/${recordId}`, {
      body,
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const error = yield response.json();
      throw new Error(error);
    }
    return yield response.json();
  } catch (error) {
    return sagaError(error);
  }
}

/**
 * Gets the the record type for the record to be confirmed and dispatches
 * the info to the store.
 *
 * @name getConfirmationRecordType
 * @function
 */
export function* getConfirmationRecordType() {
  try {
    const type = yield fetchType();
    yield put(confirmationActions.setConfirmationRecordType(type));
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Gets the confirmation status of a record found in state and dispatches
 * the info to the store.
 *
 * @name getConfirmationStatus
 * @function
 */
export function* getConfirmationStatus() {
  try {
    const record = yield fetchRecord();
    if (!record.confirmed) {
      yield put(confirmationActions.setRecordConfirmationStatus(false));
      return;
    }
    yield put(confirmationActions.setRecordConfirmationStatus(true));
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Confirms a record if it has not yet been confirmed.
 * Dispatches the status to the store.
 *
 * @name maybeConfirmRecord
 * @function
 * @param {boolean} {payload} Is the record already confirmed?
 */
export function* maybeConfirmRecord({ payload }) {
  try {
    yield put(confirmationActions.setConfirmingRecord(true));
    const type = yield select(selectors.recordType);
    if (payload === true) {
      // Already confirmed.
      yield put(confirmationActions.setConfirmingRecord(false));
      return;
    }
    if (type !== globals.TYPE_RESET) {
      yield confirmRecord();
    }
    yield put(confirmationActions.setRecordConfirmationStatus(true));
    yield put(confirmationActions.setConfirmingRecord(false));
  } catch (error) {
    sagaError(error);
  }
}

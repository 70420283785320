import * as actions from '../actions/authActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.SET_CHECKING_IS_LOGGED_IN]: (state, action) => ({
    ...state,
    checkingIsLoggedIn: action.payload,
  }),

  [actions.SET_IS_LOGGED_IN]: (state, action) => ({
    ...state,
    isLoggedIn: action.payload,
  }),

  [actions.SET_LOGGING_IN]: (state, action) => ({
    ...state,
    loggingIn: action.payload,
  }),

  [actions.SET_LOGGING_OUT]: (state, action) => ({
    ...state,
    loggingOut: action.payload,
  }),

  [actions.SET_AUTH_INFO]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [actions.SET_LOGGED_IN_USER_ID]: (state, action) => ({
    ...state,
    loggedInUserId: action.payload,
  }),

  [actions.SET_LOGGED_IN_USER_TYPE]: (state, action) => ({
    ...state,
    loggedInUserType: action.payload,
  }),

  [actions.SET_AFTER_LOGIN_REDIRECT]: (state, action) => ({
    ...state,
    afterLoginRedirect: action.payload,
  }),

  [actions.SET_AFTER_LOGOUT_REDIRECT]: (state, action) => ({
    ...state,
    afterLogoutRedirect: action.payload,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  afterLoginRedirect: null,
  afterLogoutRedirect: null,
  checkingIsLoggedIn: false,
  creatingPassword: false,
  creationMessage: '',
  isLoggedIn: false,
  loggedInUserId: null,
  loggedInUserType: null,
  loggingIn: false,
  loggingOut: false,
  loginMethods: null,
  passwordCreated: null,
};
export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

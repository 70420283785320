export const CHECK_LOGIN_METHODS = 'CHECK_LOGIN_METHODS';
export const SET_CHECKING_LOGIN_METHODS = 'SET_CHECKING_LOGIN_METHODS';
export const SET_LOGIN_METHODS = 'SET_LOGIN_METHODS';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const SET_CREATING_PASSWORD = 'SET_CREATING_PASSWORD';
export const SET_PASSWORD_CREATED = 'SET_PASSWORD_CREATED';
export const SET_PASSWORD_CREATOR_ID = 'SET_PASSWORD_CREATOR_ID';

export const checkLoginMethods = () => ({
  type: CHECK_LOGIN_METHODS,
});

export const setCheckingLoginMethods = (payload) => ({
  type: SET_CHECKING_LOGIN_METHODS,
  payload,
});

export const setLoginMethods = (payload) => ({
  type: SET_LOGIN_METHODS,
  payload,
});

export const setPasswordCreatorId = (payload) => ({
  type: SET_PASSWORD_CREATOR_ID,
  payload,
});

export const createPassword = () => ({
  type: CREATE_PASSWORD,
});

export const setCreatingPassword = () => ({
  type: SET_CREATING_PASSWORD,
});

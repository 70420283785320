export const SET_FEEDBACK = 'SET_FEEDBACK';
export const FETCH_FEEDBACK = 'FETCH_FEEDBACK';
export const SET_FETCHING_FEEDBACK = 'SET_FETCHING_FEEDBACK';
export const SUBMIT_FEEDBACK_FORM = 'SUBMIT_FEEDBACK_FORM';

export const setFeedback = (shooterId, feedback) => ({
  type: SET_FEEDBACK,
  shooterId,
  feedback,
});

export const fetchFeedback = () => ({
  type: FETCH_FEEDBACK,
});

export const setFetchingFeedback = (payload) => ({
  type: SET_FETCHING_FEEDBACK,
  payload,
});

export const submitFeedbackForm = (payload) => ({
  type: SUBMIT_FEEDBACK_FORM,
  payload,
});

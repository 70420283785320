import * as actions from '../actions/createPasswordActions';

/*
 * Action handlers
 */
const ACTION_HANDLERS = {
  [actions.SET_LOGIN_METHODS]: (state, action) => ({
    ...state,
    loginMethods: action.payload,
  }),

  [actions.SET_CHECKING_LOGIN_METHODS]: (state, action) => ({
    ...state,
    checkingLoginMethods: action.payload,
  }),

  [actions.SET_CREATING_PASSWORD]: (state, action) => ({
    ...state,
    creatingPassword: action.payload,
  }),

  [actions.SET_PASSWORD_CREATED]: (state, action) => ({
    ...state,
    passwordCreated: action.payload,
  }),

  [actions.SET_PASSWORD_CREATOR_ID]: (state, action) => ({
    ...state,
    passwordCreatorId: action.payload,
  }),
};

/*
 * Reducer
 */
const initialState = { loginMethods: [] };

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, call } from 'redux-saga/effects';
import * as globals from '../constants/globals';
import * as adminSaga from './adminSaga';
import sagaError from './sagaError';

const doCloseRequest = () => {
  const request = {
    method: 'GET',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
  };
  return fetch(`${globals.CLOSE_REQUEST_URL}`, request);
};

export function* closeRequest() {
  try {
    yield put(showLoading());
    const response = yield doCloseRequest();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(adminSaga.fetchProposalsOfRfp);
    yield call(adminSaga.fetchRequest);
    toast.success('Tarjouspyyntö suljettu');
  } catch (error) {
    sagaError(error, 'Tarjouspyynnön sulkeminen ei onnistunut');
  } finally {
    yield put(hideLoading());
  }
}

import * as actions from '../actions/confirmationActions';

/*
 * Action handlers
 */
const ACTION_HANDLERS = {
  [actions.SET_CONFIRMATION_RECORD_ID]: (state, action) => ({
    ...state,
    id: action.payload,
  }),

  [actions.SET_DECISION_TO_MAKE_AFTER_INIT]: (state, action) => ({
    ...state,
    decision: action.payload,
  }),

  [actions.SET_DECISION_RESULT]: (state, action) => ({
    ...state,
    decisionResult: action.payload,
  }),

  [actions.CHECKING_RECORD_CONFIRMATION_STATUS]: (state, action) => ({
    ...state,
    checkingRecordConfirmationStatus: action.payload,
  }),

  [actions.SET_RECORD_CONFIRMATION_STATUS]: (state, action) => ({
    ...state,
    recordConfirmationStatus: action.payload,
  }),

  [actions.SET_CONFIRMATION_RECORD_TYPE]: (state, action) => ({
    ...state,
    type: action.payload,
  }),

  [actions.SET_SHOW_CONFIRMATION]: (state, action) => ({
    ...state,
    showConfirmation: action.payload,
  }),

  [actions.SET_CONFIRMING_RECORD]: (state, action) => ({
    ...state,
    confirmingRecord: action.payload,
  }),
};

/*
 * Reducer
 */
const initialState = { showConfirmation: false };

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

/* eslint no-shadow: 1 */
import moment from 'moment-timezone';
import * as globals from '../constants/globals';
import * as questionDefs from '../constants/questions';
import * as helpers from '../helpers/helperFunctions';

const adminIds = ['60c2f9d0906e08aeb0a01eda', '5eb23b343bf9010017d1d514'];

// ------------------------------------
// Auth
// ------------------------------------
export const shooterInfo = (state) =>
  state.shooters && state.shooters[state.auth.loggedInUserId]
    ? state.shooters[state.auth.loggedInUserId]
    : null;
export const profileImage = (state) =>
  state.shooters && state.shooters[state.auth.loggedInUserId].profileImage
    ? state.shooters[state.auth.loggedInUserId].profileImage
    : null;
export const isLoggedIn = (state) => state.auth.isLoggedIn;
export const isAdmin = (state) =>
  state.auth.isLoggedIn && adminIds.includes(state.auth.loggedInUserId);
export const loggingOut = (state) => state.auth.loggingOut;
export const loggingIn = (state) => state.auth.loggingIn;
export const checkingIsLoggedIn = (state) => state.auth.checkingIsLoggedIn;
export const loggedInShooterId = (state) => state.auth.loggedInUserId;
export const loggedInRfpId = (state) => state.auth.loggedInUserId;
export const loggedInUserId = (state) => state.auth.loggedInUserId;
export const loggedInUserType = (state) => state.auth.loggedInUserType;
export const afterLoginRedirect = (state) => state.auth.afterLoginRedirect;
export const afterLogoutRedirect = (state) => state.auth.afterLogoutRedirect;
export const shooterState = (state) => {
  const shooterInfo =
    state.shooters && state.shooters[state.auth.loggedInUserId]
      ? state.shooters[state.auth.loggedInUserId]
      : null;
  if (!shooterInfo) return false;
  const { accepted, confirmed, description, experience } = shooterInfo;
  if (accepted && confirmed && description && experience) {
    return true;
  }
  return false;
};

// ------------------------------------
// Requests
// ------------------------------------
export const requests = (state) => state.requests;
export const request = (state, request) => state.requests[request];
export const rfpAdminRequest = (state) => state.requests[loggedInRfpId(state)];
export const selectedRfpAdminProposal = (state) =>
  rfpAdminRequest(state).selectedProposal;
export const feedbackGiven = (state) => rfpAdminRequest(state).feedbackGiven;
export const requestProposalId = ({ proposals }, rfpId) => {
  const proposalIds = Object.keys(proposals);
  const proposalObjects = proposalIds.map((id) => proposals[id]);
  const proposal = proposalObjects.find((o) => o.requestForProposal === rfpId);
  return proposal._id;
};
export const requestState = (state, rfpId) => {
  const { proposals, requests } = state;
  const request = requests[rfpId];
  let proposalSubmitted = false;
  let proposalSelected = false;
  let proposalCancelled = false;
  let otherShooterSelected = false;
  let cancellable = false;
  const unpaidInvoices = shooterInfo(state).unpaidInvoices;
  Object.keys(proposals).forEach((key) => {
    if (proposals[key].requestForProposal === request._id) {
      proposalSubmitted = true;
      if (proposals[key].cancelled === true) {
        proposalCancelled = true;
      }
      if (
        moment(proposals[key].createdOn).isBefore(moment().subtract(1, 'days'))
      ) {
        cancellable = true;
      }
    }
    if (
      Object.keys(proposals).some(
        (proposalKey) => proposalKey === request.selectedProposal
      )
    ) {
      proposalSelected = true;
    }
    if (
      request.selectedProposal &&
      Object.keys(proposals).every(
        (proposalKey) => proposalKey !== request.selectedProposal
      )
    ) {
      otherShooterSelected = true;
    }
  });
  if (!shooterState(state)) {
    return globals.REQUEST_STATE_MISSING_INFO;
  }
  if (unpaidInvoices) {
    return globals.REQUEST_STATE_UNPAID_INVOICES;
  }
  if (proposalSubmitted && proposalCancelled) {
    return globals.REQUEST_STATE_CANCELLED;
  }
  if (proposalSubmitted && proposalSelected) {
    return globals.REQUEST_STATE_WON;
  }
  if (request.isOpenForProposals && !proposalSubmitted) {
    return globals.REQUEST_STATE_PROPOSABLE;
  }
  if (request.isOpenForProposals && proposalSubmitted && cancellable) {
    return globals.REQUEST_STATE_CANCELLABLE;
  }
  if (request.isOpenForProposals && proposalSubmitted) {
    return globals.REQUEST_STATE_PROPOSED;
  }
  if (otherShooterSelected && proposalSubmitted) {
    return globals.REQUEST_STATE_LOST;
  }
  if (!request.isOpenForProposals) {
    return globals.REQUEST_STATE_CLOSED;
  }
  return globals.REQUEST_STATE_CLOSED;
};
export const weddings = (state) =>
  Object.keys(state.requests)
    .filter((key) => !!state.requests[key].proposalSelected)
    .map((key) => state.requests[key]);

// ------------------------------------
// Proposals
// ------------------------------------
export const proposals = (state) => state.proposals;
export const openProposal = (state, proposalId) => state.proposals[proposalId];
export const openShooter = (state, proposalId) => {
  const openProposal = state.proposals[proposalId];
  return state.shooters[
    Object.keys(state.shooters).find((key) => openProposal.shooter === key)
  ];
};
export const proposalByRequest = (state, request) =>
  state.proposals[
    Object.keys(state.proposals).find(
      (key) => state.proposals[key].requestForProposal === request
    )
  ];
export const proposalIdByRequest = (state, request) => {
  const proposal =
    state.proposals[
      Object.keys(state.proposals).find(
        (key) => state.proposals[key].requestForProposal === request
      )
    ];
  if (proposal) {
    return proposal._id;
  }
  return false;
};
export const proposableRFPAmount = (state) => {
  const { fetchingRequests, fetchingProposals } = state.admin;
  if (fetchingRequests || fetchingProposals) return 0;
  const { requests, proposals } = state;
  return Object.keys(requests).filter(
    (requestKey) =>
      requests[requestKey].isOpenForProposals &&
      Object.keys(proposals).every(
        (proposalKey) =>
          proposals[proposalKey].requestForProposal !== requestKey
      )
  ).length;
};
export const previousProposalOptions = (state) =>
  Object.keys(state.proposals).map((proposalKey) => {
    const request = Object.keys(state.requests).find(
      (requestKey) =>
        requestKey === state.proposals[proposalKey].requestForProposal
    );
    const date = moment(state.requests[request].date)
      .tz('Europe/Helsinki')
      .format('DD.MM.YYYY');
    const createdOn = moment(state.proposals[proposalKey].createdOn)
      .tz('Europe/Helsinki')
      .format('DD.MM.YYYY');
    const label = `${date} ${state.requests[request].location} (jätetty ${createdOn})`;
    return { value: state.proposals[proposalKey], label };
  });
export const proposalChosen = (state) =>
  Object.keys(state.proposals).some(
    (key) => state.proposals[key].chosenProposal
  );
export const proposalCancelled = (state) =>
  Object.keys(state.proposals).some((key) => state.proposals[key].cancelled);
export const isChosenProposal = (state, proposalId) =>
  state.proposals[proposalId].chosenProposal || false;
export const previousProposal = (state) =>
  Object.keys(state.proposals).length
    ? Object.keys(state.proposals)
        .map((key) => state.proposals[key])
        .reduce((acc, curr) => {
          if (!acc) return curr;
          const accTime = new Date(acc.createdOn);
          const accTimetamp = accTime.getTime();
          const currTime = new Date(curr.createdOn);
          const currTimetamp = currTime.getTime();
          if (currTimetamp > accTimetamp) return curr;
          return acc;
        })
    : null;
export const selectedShooter = (state, selectedProposal) =>
  state.shooters[state.proposals[selectedProposal].shooter];
export const selectedPrice = (state, selectedProposal) =>
  state.proposals[selectedProposal].proposedAmount;

// ------------------------------------
// Shooters
// ------------------------------------
export const shooters = (state) => state.shooters;
export const shooterIds = (state) => Object.keys(state.shooters);
export const dashboardShooter = (state) =>
  state.shooters[state.auth.loggedInUserId];
export const shooterName = (state) =>
  state.shooters[state.auth.loggedInUserId].name;

// ------------------------------------
// ShootersList
// ------------------------------------
export const shootersList = (state) => state;

// ------------------------------------
// Confirmation
// ------------------------------------
export const recordConfirmationStatus = (state) =>
  state.recordConfirmationStatus;
export const checkingRecordConfirmationStatus = (state) =>
  state.checkingRecordConfirmationStatus;
export const recordType = (state) => state.confirmation.type;
export const recordId = (state) => state.confirmation.id;
export const decision = (state) => state.confirmation.decision;
export const showConfirmation = (state) => state.confirmation.showConfirmation;
export const confirmationHeading = (state) =>
  state.view.initializingView ? '' : 'Kiitos';
export const confirmationInfo = (state) => {
  if (state.view.initializingView) return '';
  const { type, decision, decisionResult } = state.confirmation;
  if (decision && !decisionResult) {
    return 'Odotellaan valonnopeutta.';
  }
  if (decision && decisionResult) {
    return decision === globals.DECISION_ACCEPT
      ? `Kuvaaja on nyt hyväksytty kuvaajaksi palveluun.`
      : `Kuvaajan hakemus on hylätty.`;
  }
  if (type === globals.TYPE_RFP) {
    return 'Tarjouspyyntönne on vahvistettu ja välitetty ammattilaisillemme. Alatte pian saada tarjouksia sähköpostiinne.';
  }
  if (type === globals.TYPE_SHOOTER) {
    return 'Hakemuksesi on vastaanotettu. Saat ilmoituksen päätöksestä sähköpostiisi, kun se on käsitelty.';
  }
  if (type === globals.TYPE_RESET) {
    return 'Salasanasi on nyt alustettu. Linkki uuden salasanan luomiseksi on lähetetty sähköpostiisi.';
  }
  return '';
};

// ------------------------------------
// Galleries
// ------------------------------------
export const shooterGallery = (state) =>
  state.galleries[state.auth.loggedInUserId];
export const proposalGallery = (state, proposalId) => {
  const openProposal = state.proposals[proposalId];
  const shooter =
    state.shooters[
      Object.keys(state.shooters).find((key) => openProposal.shooter === key)
    ];
  return state.galleries[shooter._id];
};

// ------------------------------------
// Feedback
// ------------------------------------
export const proposalFeedback = (state, proposalId) =>
  state.feedback[state.proposals[proposalId].shooter].find(
    (feedback) => feedback.requestForProposal === state.auth.loggedInUserId
  );
export const shooterFeedback = (state, proposalId) => {
  if (proposalId) {
    const openProposal = state.proposals[proposalId];
    const shooter =
      state.shooters[
        Object.keys(state.shooters).find((key) => openProposal.shooter === key)
      ];
    return state.feedback[shooter._id];
  }
  return state.feedback[state.auth.loggedInUserId];
};

// ------------------------------------
// Questions
// ------------------------------------
export const proposalQuestions = (state, proposalId) => {
  const questionIds = Object.keys(state.questions).filter(
    (questionId) => state.questions[questionId].proposal === proposalId
  );
  return questionIds.map((id) => state.questions[id]);
};
// Check if last question is answered.
export const answeringEnabled = (state, proposalId) => {
  const questions = proposalQuestions(state, proposalId);
  questions.sort(
    (a, b) =>
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      new Date(b.createdOn) - new Date(a.createdOn)
  );
  return questions[0].answered === false;
};
// Check if last question is answered.
export const latestQuestionId = (state, proposalId) => {
  const questions = proposalQuestions(state, proposalId);
  questions.sort(
    (a, b) =>
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      new Date(b.createdOn) - new Date(a.createdOn)
  );
  return questions[0]._id;
};

// ------------------------------------
// Admin
// ------------------------------------
export const submittingProposal = (state) => state.admin.submittingProposal;
export const submittingQuestion = (state) => state.admin.submittingQuestion;
export const submittingFeedback = (state) => state.admin.submittingFeedback;
export const fetchingProposals = (state) => state.admin.fetchingProposals;
export const fetchingRequests = (state) => state.admin.fetchingRequests;
export const fetchingGallery = (state) => state.admin.fetchingGallery;
export const savingProfile = (state) => state.admin.savingProfile;
export const fetchingShooters = (state) => state.admin.fetchingShooters;
export const canShowBadge = (state) =>
  !state.admin.fetchingShooters &&
  !state.admin.fetchingProposals &&
  !state.admin.fetchingRequests;

// ------------------------------------
// View
// ------------------------------------
export const initializingView = (state) => state.view.initializingView;
export const rfp = (state) => state.requests[state.auth.loggedInUserId];

// ------------------------------------
// CreatePassword
// ------------------------------------
export const passwordCreatorId = (state) =>
  state.createPassword.passwordCreatorId;
export const loginMethods = (state) => state.createPassword.loginMethods;
export const createPasswordHeading = (state) =>
  !state.createPassword.loginMethods.includes(
    globals.LOGIN_METHOD_USERNAME_PASSWORD
  )
    ? 'Luo salasana'
    : 'Olet jo luonut salasanan';
export const createPasswordInfo = (state) =>
  !state.createPassword.loginMethods.includes(
    globals.LOGIN_METHOD_USERNAME_PASSWORD
  )
    ? 'Luo vähintään kahdeksankirjaiminen salasana, jolla voit jatkossa kirjautua palveluun.'
    : 'Kirjaudu palveluun sähköpostiosoitteella ja salasanalla tai valitse kirjautumisessa salasanan palautus, jos olet unohtanut salasanan.';
export const createPasswordCTA = (state) =>
  !state.createPassword.loginMethods.includes(
    globals.LOGIN_METHOD_USERNAME_PASSWORD
  )
    ? 'Luo salasana'
    : 'Kirjaudu sisään';

// ------------------------------------
// Form
// ------------------------------------
export const answers = (state) => state.form.answers;
export const answer = (state, slug) => state.form.answers[slug];
export const username = (state) =>
  state.form.answers[questionDefs.QUESTION_EMAIL];
export const password = (state) =>
  state.form.answers[questionDefs.QUESTION_PASSWORD];
export const questionFocus = (state) => state.form.questionFocused;
export const questionAnswered = (state) => state.form.questionAnswered;
export const questions = (state) => state.form.questions;
export const currentQuestionIndex = (state) => state.form.currentQuestionIndex;
export const currentProgress = (state) => {
  if (
    state.form.submittingForm ||
    state.auth.loggingIn ||
    state.auth.isLoggedIn
  )
    return 100;
  return (state.form.currentQuestionIndex * 100) / state.form.questions.length;
};
export const isLastQuestion = (state) =>
  state.form.currentQuestionIndex === state.form.questions.length - 1;
export const formError = (state) =>
  helpers.getErrorDescription(state.form.error);
export const form = (state) => state.form;
export const formType = (state) => state.form.type;
export const formSlug = (state) => state.form.slug;
export const formOpen = (state) => state.form.formOpen;
export const formExited = (state) => state.form.formExited;
export const formReadyToOpen = (state) => state.form.formReadyToOpen;
export const submittingForm = (state) => state.form.submittingForm;
export const formSubmitResult = (state) => state.form.formSubmitResult;
export const currentQuestion = (state) =>
  state.form.questions[state.form.currentQuestionIndex];
export const currentAnswer = (state) => {
  const { slug } = state.form.questions[state.form.currentQuestionIndex];
  return state.form.answers[slug];
};

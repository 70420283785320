// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_LOGGING_IN = 'SET_LOGGING_IN';
export const SET_LOGGING_OUT = 'SET_LOGGING_OUT';
export const SET_LOGGED_IN_USER_ID = 'SET_LOGGED_IN_USER_ID';
export const SET_LOGGED_IN_USER_TYPE = 'SET_LOGGED_IN_USER_TYPE';
export const SET_LOGIN_RESULT = 'SET_LOGIN_RESULT';
export const LOGGED_IN_STATUS_REQUEST = 'LOGGED_IN_STATUS_REQUEST';
export const SET_CHECKING_IS_LOGGED_IN = 'SET_CHECKING_IS_LOGGED_IN';
export const SET_AFTER_LOGIN_REDIRECT = 'SET_SET_AFTER_LOGIN_REDIRECT';
export const SET_AFTER_LOGOUT_REDIRECT = 'SET_SET_AFTER_LOGOUT_REDIRECT';

export const login = () => ({
  type: LOGIN,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setAuthInfo = (payload) => ({
  type: SET_AUTH_INFO,
  payload,
});

export const setAfterLoginRedirect = (payload) => ({
  type: SET_AFTER_LOGIN_REDIRECT,
  payload,
});

export const setAfterLogoutRedirect = (payload) => ({
  type: SET_AFTER_LOGOUT_REDIRECT,
  payload,
});

export const loggedInStatusRequest = () => ({
  type: LOGGED_IN_STATUS_REQUEST,
});

export const setCheckingIsLoggedIn = (payload) => ({
  type: SET_CHECKING_IS_LOGGED_IN,
  payload,
});

export const setLoggingIn = (payload) => ({
  type: SET_LOGGING_IN,
  payload,
});

export const setLoggingOut = (payload) => ({
  type: SET_LOGGING_OUT,
  payload,
});

export const setIsLoggedIn = (payload) => ({
  type: SET_IS_LOGGED_IN,
  payload,
});

export const setLoggedInUserId = (payload) => ({
  type: SET_LOGGED_IN_USER_ID,
  payload,
});

export const setLoggedInUserType = (payload) => ({
  type: SET_LOGGED_IN_USER_TYPE,
  payload,
});

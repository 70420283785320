import * as globals from '../constants/globals';
import sagaError from './sagaError';

/**
 * Submits a survey response.
 *
 * @name submitSurvey
 * @function
 * @param {boolean} {payload} Survey answer and rfp id.
 */
export function* submitSurvey({ answerId, rfpId }) {
  try {
    const body = JSON.stringify({
      answerId,
      rfpId,
    });
    const response = yield fetch(globals.SURVEY_URL, {
      body,
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const error = yield response.json();
      throw new Error(error);
    }
  } catch (error) {
    sagaError(error);
  }
}

import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import admin from './adminReducer';
import auth from './authReducer';
import confirmation from './confirmationReducer';
import createPassword from './createPasswordReducer';
import feedback from './feedbackReducer';
import form from './formReducer';
import galleries from './galleriesReducer';
import proposals from './proposalsReducer';
import questions from './questionsReducer';
import requests from './requestsReducer';
import shooters from './shootersReducer';
import shootersList from './shootersListReducer';
import view from './viewReducer';

const rootReducer = combineReducers({
  admin,
  auth,
  confirmation,
  createPassword,
  feedback,
  form,
  galleries,
  loadingBar: loadingBarReducer,
  proposals,
  questions,
  requests,
  shooters,
  shootersList,
  view,
});

export default rootReducer;

import * as formActions from '../actions/formActions';
import * as globals from '../constants/globals';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [formActions.SET_QUESTIONS]: (state, action) => ({
    ...state,
    questions: action.payload,
    answers: action.payload.reduce((obj, item) => {
      obj[item.slug] = item.defaultAnswer; // eslint-disable-line
      return obj;
    }, {}),
  }),

  [formActions.SET_FORM_OPEN]: (state, action) => ({
    ...state,
    formOpen: action.payload,
  }),

  [formActions.SET_FORM_EXITED]: (state, action) => ({
    ...state,
    formExited: action.payload,
  }),

  [formActions.SET_FORM_READY_TO_OPEN]: (state, action) => ({
    ...state,
    formReadyToOpen: action.payload,
  }),

  [formActions.SET_FORM_TYPE]: (state, action) => ({
    ...state,
    type: action.payload,
  }),

  [formActions.SET_FORM_SLUG]: (state, action) => ({
    ...state,
    slug: action.payload,
  }),

  [formActions.SET_QUESTION_ANSWERED]: (state, action) => ({
    ...state,
    questionAnswered: action.payload,
  }),

  [formActions.SET_QUESTION_FOCUS]: (state, action) => ({
    ...state,
    questionFocused: action.payload,
  }),

  [formActions.SET_CURRENT_QUESTION_INDEX]: (state, action) => ({
    ...state,
    currentQuestionIndex: action.payload,
  }),

  [formActions.QUESTION_INCREMENT]: (state) => ({
    ...state,
    currentQuestionIndex: state.currentQuestionIndex + 1,
  }),

  [formActions.QUESTION_DECREMENT]: (state) => ({
    ...state,
    currentQuestionIndex: state.currentQuestionIndex - 1,
  }),

  [formActions.SET_ANSWER]: (state, action) => {
    const { question, answer } = action;
    return {
      ...state,
      answers: { ...state.answers, [question]: answer },
    };
  },

  [formActions.SET_FORM_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [formActions.SET_SUBMITTING_FORM]: (state, action) => ({
    ...state,
    submittingForm: action.payload,
  }),

  [formActions.SET_FORM_SUBMIT_RESULT]: (state, action) => ({
    ...state,
    formSubmitResult: action.payload,
  }),

  [formActions.RESET_FORM_AFTER_FAILED_LOGIN]: (state) => ({
    ...state,
    error: globals.INVALID_LOGIN,
    currentQuestionIndex: 0,
    formOpen: true,
    questionFocused: true,
    questionAnswered: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function Reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export const SET_REQUESTS = 'SET_REQUESTS';
export const CLOSE_REQUEST = 'CLOSE_REQUEST';

export const setRequests = (payload) => ({
  type: SET_REQUESTS,
  payload,
});

export const closeRfp = () => ({
  type: CLOSE_REQUEST,
});

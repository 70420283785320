/* eslint no-param-reassign: 0 */
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, call } from 'redux-saga/effects';
import * as globals from '../constants/globals';
import * as adminActions from '../actions/adminActions';
import * as questionActions from '../actions/questionActions';
import { arrayToObject } from '../helpers/helperFunctions';
import sagaError from './sagaError';

function* doFetchQuestions() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    redirect: 'follow',
  };
  return yield fetch(globals.QUESTIONS_URL, request);
}

export const doSubmitNewQuestion = (data) => {
  const body = JSON.stringify(data);
  const request = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(globals.QUESTIONS_URL, request);
};

// TODO: add question id
export const doAnswerQuestion = (data) => {
  const { questionId } = data;
  const payload = {
    answer: data.answer,
  };
  // Make the call
  const body = JSON.stringify(payload);
  const request = {
    method: 'PUT',
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'follow',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${globals.QUESTIONS_URL}/${questionId}`, request);
};

/**
 * Fetch questions
 *
 * @name fetchQuestions
 * @function
 */
export function* fetchQuestions() {
  try {
    const response = yield doFetchQuestions();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    const questions = arrayToObject(result);
    yield put(questionActions.setProposalQuestions(questions));
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Submit a new question
 *
 * @name submitNewQuestion
 * @function
 * @param {object} payload Question data
 */
export function* submitNewQuestion({ payload }) {
  try {
    yield put(adminActions.setSubmittingQuestion(true));
    yield put(showLoading());
    const response = yield doSubmitNewQuestion(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(fetchQuestions);
    toast.success('Viesti jätetty');
  } catch (error) {
    sagaError(error, 'Viestin jättäminen ei onnistunut');
  } finally {
    yield put(adminActions.setSubmittingQuestion(false));
    yield put(hideLoading());
  }
}

/**
 * Submit an answer to a question
 *
 * @name answerQuestion
 * @function
 * @param {object} payload Answer data
 */
export function* answerQuestion({ payload }) {
  try {
    yield put(adminActions.setSubmittingQuestion(true));
    yield put(showLoading());
    const response = yield doAnswerQuestion(payload);
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    yield call(fetchQuestions);
    toast.success('Vastaus jätetty');
  } catch (error) {
    sagaError(error, 'Vastauksen jättäminen ei onnistunut');
  } finally {
    yield put(adminActions.setSubmittingQuestion(false));
    yield put(hideLoading());
  }
}

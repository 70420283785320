import { call } from 'redux-saga/effects';

export function* createRfpAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Signup',
      eventAction: 'newRfp',
      eventLabel: 'New RFP created',
    });
  }
}

export function* signupAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Signup',
      eventAction: 'signup',
      eventLabel: 'New shooter signup',
    });
  }
}

export function* forgotPasswordAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Login',
      eventAction: 'forgotPassword',
      eventLabel: 'Forgot password form submitted',
    });
  }
}

export function* loginAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Login',
      eventAction: 'login',
      eventLabel: 'User logged in',
    });
  }
}

export function* logoutAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Login',
      eventAction: 'logout',
      eventLabel: 'User logged out',
    });
  }
}

export function* submitProposalAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'Shooter',
      eventAction: 'submitProposal',
      eventLabel: 'Proposal submitted',
    });
  }
}

export function* submitFeedbackAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'RFP',
      eventAction: 'submitFeedback',
      eventLabel: 'Feedback submitted',
    });
  }
}

export function* chooseProposalAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'RFP',
      eventAction: 'chooseProposal',
      eventLabel: 'Proposal chosen',
    });
  }
}

export function* chooseProposalButtonAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'RFP',
      eventAction: 'chooseProposalButton',
      eventLabel: 'Choose proposal button clicked',
    });
  }
}

export function* cancelProposalButtonAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'RFP',
      eventAction: 'cancelProposalButton',
      eventLabel: 'Cancel proposal button clicked',
    });
  }
}

export function* closeRfpButtonAnalytics() {
  if (window && window.ga) {
    yield call(window.ga, 'send', {
      hitType: 'event',
      eventCategory: 'RFP',
      eventAction: 'closeRequestButton',
      eventLabel: 'Close request button clicked',
    });
  }
}

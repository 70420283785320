import {
  PATHNAME_HOME,
  PATHNAME_JOIN,
  PATHNAME_LOGIN,
  PATHNAME_FORGOT,
} from '../constants/globals';
import {
  rfpQuestions,
  loginQuestions,
  passwordQuestions,
  signUpQuestions,
  forgotQuestions,
} from '../constants/questions';

/*
 * Constants
 */
export const QUESTION_DECREMENT = 'QUESTION_DECREMENT';
export const QUESTION_INCREMENT = 'QUESTION_INCREMENT';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_CURRENT_QUESTION_INDEX = 'SET_CURRENT_QUESTION_INDEX';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const SET_FORM_ERROR_STATUS = 'SET_FORM_ERROR_STATUS';
export const SET_FORM_EXITED = 'SET_FORM_EXITED';
export const SET_FORM_OPEN = 'SET_FORM_OPEN';
export const SET_FORM_READY_TO_OPEN = 'SET_FORM_READY_TO_OPEN';
export const SET_FORM_SUBMIT_RESULT = 'SET_FORM_SUBMIT_RESULT';
export const SET_FORM_TYPE = 'SET_FORM_TYPE';
export const SET_FORM_SLUG = 'SET_FORM_SLUG';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_QUESTION_ANSWERED = 'SET_QUESTION_ANSWERED';
export const SET_QUESTION_FOCUS = 'SET_QUESTION_FOCUS';
export const SET_SUBMITTING_FORM = 'SET_SUBMITTING_FORM';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const RESET_FORM_AFTER_FAILED_LOGIN = 'RESET_FORM_AFTER_FAILED_LOGIN';
export const SUBMIT_RFP_FORM = 'SUBMIT_RFP_FORM';
export const SUBMIT_NEW_SHOOTER_FORM = 'SUBMIT_NEW_SHOOTER_FORM';
export const SUBMIT_FORTGOT_PASSWORD_FORM = 'SUBMIT_FORTGOT_PASSWORD_FORM';

/*
 * Actions
 */
export const submitForm = () => ({
  type: SUBMIT_FORM,
});

export const submitRfpForm = () => ({
  type: SUBMIT_RFP_FORM,
});

export const submitNewShooterForm = () => ({
  type: SUBMIT_NEW_SHOOTER_FORM,
});

export const submitForgotPasswordForm = () => ({
  type: SUBMIT_FORTGOT_PASSWORD_FORM,
});

export const resetFormAfterFailedLogin = () => ({
  type: RESET_FORM_AFTER_FAILED_LOGIN,
});

export const setQuestions = (questions) => ({
  type: SET_QUESTIONS,
  payload: questions,
});

export const setQuestionsForView = (view) => {
  let questions;
  switch (view) {
    case PATHNAME_HOME:
      questions = rfpQuestions;
      break;
    case PATHNAME_JOIN:
      questions = signUpQuestions;
      break;
    case PATHNAME_LOGIN:
      questions = loginQuestions;
      break;
    case PATHNAME_FORGOT:
      questions = forgotQuestions;
      break;
    default:
      questions = passwordQuestions;
  }
  return setQuestions(questions);
};

export const setFormReadyToOpen = (payload = true) => ({
  type: SET_FORM_READY_TO_OPEN,
  payload,
});

export const setFormOpen = (payload = true) => ({
  type: SET_FORM_OPEN,
  payload,
});

export const setFormExited = (payload = true) => ({
  type: SET_FORM_EXITED,
  payload,
});

export const setFormType = (type) => ({
  type: SET_FORM_TYPE,
  payload: type,
});

export const setFormSlug = (slug) => ({
  type: SET_FORM_SLUG,
  payload: slug,
});

export const setQuestionAnswered = (payload) => ({
  type: SET_QUESTION_ANSWERED,
  payload,
});

export const setAnswer = (question, answer) => ({
  type: SET_ANSWER,
  question,
  answer,
});

export const setQuestionFocus = (value = false) => ({
  type: SET_QUESTION_FOCUS,
  payload: value,
});

export const setCurrentQuestionIndex = (payload) => ({
  type: SET_CURRENT_QUESTION_INDEX,
  payload,
});

export const questionIncrement = () => ({
  type: QUESTION_INCREMENT,
});

export const questionDecrement = () => ({
  type: QUESTION_DECREMENT,
});

export const setFormError = (error) => ({
  type: SET_FORM_ERROR,
  payload: error,
});

export const setSubmittingForm = (payload = true) => ({
  type: SET_SUBMITTING_FORM,
  payload,
});

export const setFormSubmitResult = (payload = '') => ({
  type: SET_FORM_SUBMIT_RESULT,
  payload,
});

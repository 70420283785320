import { toast } from 'react-toastify';
import * as globals from '../constants/globals';

const SagaError = (error, msg = 'Tapahtui virhe, ylläpitoa on tiedotettu') => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error.toString());
  }
  toast.error(msg);
};

export default SagaError;

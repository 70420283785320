export const ADMIN_READY_TO_INITIALIZE = 'ADMIN_READY_TO_INITIALIZE';
export const FETCH_PROPOSALS_BY_SHOOTER_ID = 'FETCH_PROPOSALS_BY_SHOOTER_ID';
export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const FETCH_SHOOTER = 'FETCH_SHOOTER';
export const SAVE_SHOOTER_PROFILE = 'SAVE_SHOOTER_PROFILE';
export const SAVE_SHOOTER_PROFILE_IMAGE = 'SAVE_SHOOTER_PROFILE_IMAGE';
export const SET_FETCHING_PROPOSALS = 'SET_FETCHING_PROPOSALS';
export const SET_FETCHING_REQUESTS = 'SET_FETCHING_REQUESTS';
export const SET_FETCHING_SHOOTERS = 'SET_FETCHING_SHOOTERS';
export const SET_SAVING_PROFILE = 'SET_SAVING_PROFILE';
export const SET_SUBMITTING_PROPOSAL = 'SET_SUBMITTING_PROPOSAL';
export const SET_SUBMITTING_FEEDBACK = 'SET_SUBMITTING_FEEDBACK';
export const SET_SUBMITTING_QUESTION = 'SET_SUBMITTING_QUESTION';
export const SET_CANCELING_PROPOSAL = 'SET_CANCELING_PROPOSAL';
export const SET_CHOOSING_PROPOSAL = 'SET_CHOOSING_PROPOSAL';

export const saveShooterProfile = (payload) => ({
  type: SAVE_SHOOTER_PROFILE,
  payload,
});

export const saveShooterProfileImage = (payload) => ({
  type: SAVE_SHOOTER_PROFILE_IMAGE,
  payload,
});

export const fetchShooter = () => ({
  type: FETCH_SHOOTER,
});

export const fetchRequests = () => ({
  type: FETCH_REQUESTS,
});

export const fetchProposalsByShooterId = () => ({
  type: FETCH_PROPOSALS_BY_SHOOTER_ID,
});

export const setFetchingShooters = (payload) => ({
  type: SET_FETCHING_SHOOTERS,
  payload,
});

export const setFetchingProposals = (payload) => ({
  type: SET_FETCHING_PROPOSALS,
  payload,
});

export const setFetchingRequests = (payload) => ({
  type: SET_FETCHING_REQUESTS,
  payload,
});

export const setSubmittingProposal = (payload) => ({
  type: SET_SUBMITTING_PROPOSAL,
  payload,
});

export const setSubmittingFeedback = (payload) => ({
  type: SET_SUBMITTING_FEEDBACK,
  payload,
});

export const setCancelingProposal = (payload) => ({
  type: SET_CANCELING_PROPOSAL,
  payload,
});

export const setChoosingProposal = (payload) => ({
  type: SET_CHOOSING_PROPOSAL,
  payload,
});

export const setSubmittingQuestion = (payload) => ({
  type: SET_SUBMITTING_QUESTION,
  payload,
});

export const setSavingProfile = (payload) => ({
  type: SET_SAVING_PROFILE,
  payload,
});

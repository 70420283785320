import React from 'react';
import * as globals from '../constants/globals';

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;
  setTimeout(() => {
    element.scrollTop += perTick; // eslint-disable-line
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
};

/**
 * Takes an error constant and returns a description.
 *
 * @param {string} input
 * @returns {string|false}
 */
export function getErrorDescription(input) {
  if (input === globals.INVALID_EMPTY) {
    return 'Ole hyvä ja täytä kenttä ennen jatkamista';
  }
  if (input === globals.INVALID_NONSELECTED) {
    return 'Valitse ainakin yksi vaihtoehdoista';
  }
  if (input === globals.INVALID_MALFORMED_DATE) {
    return 'Anna päivämäärä muodossa pp.kk.vvvv';
  }
  if (input === globals.INVALID_TOO_LATE) {
    return 'Kyselyjä voi tehdä enintään kolmen vuoden päähän';
  }
  if (input === globals.INVALID_IN_PAST) {
    return 'Tarkista päivämäärä';
  }
  if (input === globals.INVALID_TOO_SOON) {
    return 'Kyselyjä voi tehdä aikaisintaan viikon päähän';
  }
  if (input === globals.INVALID_EMAIL_INVALID) {
    return 'Sähköpostiosoite ei kelpaa';
  }
  if (input === globals.INVALID_LOCATION) {
    return 'Käytä paikkakunnan virallista nimeä';
  }
  if (input === globals.INVALID_NAMES) {
    return 'Käytä etunimiänne, laita väliin &, ja tai sekä';
  }
  if (input === globals.INVALID_ORGCODE) {
    return 'Sinulla on oltava y-tunnus. Tarkista tunnuksen muoto.';
  }
  if (input === globals.INVALID_EMAIL_HAS_RFP_OPEN) {
    return 'Tällä osoitteella on jo avoin, vahvistettu tarjouspyyntö.';
  }
  if (input === globals.INVALID_EMAIL_TAKEN_FOR_SHOOTER) {
    return 'Tämä sähköpostiosoite on jo käytössä.';
  }
  if (input === globals.INVALID_PASSWORD_TOO_SHORT) {
    return 'Salasana on liian lyhyt.';
  }
  if (input === globals.INVALID_PASSWORDS_DONT_MATCH) {
    return 'Salasanat eivät täsmää.';
  }
  if (input === globals.INVALID_LOGIN) {
    return 'Kirjautuminen epäonnistui.';
  }
  if (input === globals.INVALID_WWW) {
    return 'Käytä http:// tai https:// -etuliitteistä osoitetta.';
  }
  return '';
}

/**
 * typesIcons
 *
 * @param elementSlug
 * @param types
 * @returns icons
 */
export function typesIcons(elementSlug, types, printTypeName = false) {
  const icons = [];
  globals.photoTypes.forEach((type) => {
    const typeSlug = type[1];
    const typeName = type[0];
    const isItWanted = types.includes(typeSlug) ? 'wanted' : '';
    icons.push(
      <div
        key={`${elementSlug}${typeName}`}
        className={`type-with-desc ${isItWanted}`}
      >
        <span
          key={typeName}
          data-tip={`${typeName}: ${types.includes(typeSlug) ? 'kyllä' : 'ei'}`}
          className={`${elementSlug}__icon ${elementSlug}__icon--${typeSlug} ${isItWanted}`}
        />
        {printTypeName && (
          <span className={`${elementSlug}__typename`}>{typeName}</span>
        )}
      </div>
    );
  });
  return icons;
}

export const getRFPTableRequestStatus = (proposals, request) => {
  const proposalSubmitted = Object.keys(proposals).some(
    (proposalKey) => proposals[proposalKey].requestForProposal === request._id
  );
  const proposalCancelled =
    proposalSubmitted &&
    Object.keys(proposals).some(
      (proposalKey) =>
        proposals[proposalKey].requestForProposal === request._id &&
        proposals[proposalKey].cancelled
    );
  const proposalSelected = Object.keys(proposals).some(
    (proposalKey) => proposalKey === request.selectedProposal
  );
  const otherShooterSelected =
    request.selectedProposal &&
    Object.keys(proposals).every(
      (proposalKey) => proposalKey !== request.selectedProposal
    );
  if (proposalCancelled) {
    return (
      <div className="rfp-status rfp-status--cancelled">
        <span className="rfp-status-icon rfp-status-icon--cancelled" />
        Peruttu
      </div>
    );
  }
  if (proposalSubmitted && proposalSelected) {
    return (
      <div className="rfp-status rfp-status--won">
        <span className="rfp-status-icon rfp-status-icon--won" />
        Voitettu
      </div>
    );
  }
  if (request.isOpenForProposals && !proposalSubmitted) {
    return (
      <div className="rfp-status rfp-status--offer">
        <span className="rfp-status-icon rfp-status-icon--offer" />
        Avoinna
      </div>
    );
  }
  if (request.isOpenForProposals && proposalSubmitted) {
    return (
      <div className="rfp-status rfp-status--offered">
        <span className="rfp-status-icon rfp-status-icon--offered" />
        Tarjottu
      </div>
    );
  }
  if (otherShooterSelected && proposalSubmitted) {
    return (
      <div className="rfp-status rfp-status--not-selected">
        <span className="rfp-status-icon rfp-status-icon--not-selected" />
        Hävitty
      </div>
    );
  }
  if (!request.isOpenForProposals) {
    return (
      <div className="rfp-status rfp-status--closed">
        <span className="rfp-status-icon rfp-status-icon--closed" />
        Suljettu
      </div>
    );
  }
  return 'Tuntematon';
};

export function getProposalState(proposal, request) {
  if (!request.proposalSelectedOn) {
    return 'odottaa';
  }
  if (!proposal.chosenProposal) {
    return 'ei toteutunut';
  }
  if (proposal.chosenProposal) {
    return 'voitettu';
  }
  return 'tuntematon';
}

export function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

/**
 * Convert an array to a keyed object by _id
 *
 * @param [array]
 * @returns {object}
 */
export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item._id] = item; // eslint-disable-line
    return obj;
  }, {});

/**
 * Takes a gallery as it is in the state and turns it into photos
 * accepted by the Gallery component.
 *
 * @param gallery
 * @returns {object}
 */
export const mapPhotosFromGallery = (gallery) =>
  gallery.map((img) => ({
    src: img.location,
    height: img.height,
    width: img.width,
    blurImage: img.blurImage,
    imageId: img._id,
  }));

/**
 * Styles react-select. Takes the provided styles and the current state and returns the resulting styles.
 *
 * @name reactSelectStyles
 * @function
 */
export const reactSelectStyles = {
  clearIndicator: (provided) => ({
    ...provided,
    '&:hover': { cursor: 'pointer' },
  }),
  container: (provided) => ({
    ...provided,
    fontFamily: 'ff-tisa-web-pro, georgia, serif',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0',
    borderColor:
      state.isFocused || state.isSelected || state.isHovered || state.hasValue
        ? '#4ab19d'
        : '#ededed',
    backgroundColor: '#f9f9f9',
    boxShadow: 'inset 0 10px 10px rgba(0, 0, 0, 0.025)',
    '&:hover': {
      borderColor: '#4ab19d',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    '&:hover': { cursor: 'pointer' },
  }),
  // group: (provided, state) => ({ ...provided }),
  // groupHeading: (provided, state) => ({ ...provided }),
  // indicatorsContainer: (provided, state) => ({ ...provided }),
  // indicatorSeparator: (provided, state) => ({ ...provided }),
  input: (provided) => ({ ...provided, fontSize: '1.5rem' }),
  // loadingIndicator: (provided, state) => ({ ...provided }),
  // loadingMessage: (provided, state) => ({ ...provided }),
  menu: (provided) => ({ ...provided, borderRadius: '0', zIndex: 99 }),
  // menuList: (provided, state) => ({ ...provided }),
  // menuPortal: (provided, state) => ({ ...provided }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '1.5rem',
    borderRadius: 0,
    padding: '0.5rem',
  }),
  multiValueLabel: (provided) => ({ ...provided, fontSize: '1,5rem' }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'inherit' },
  }),
  // noOptionsMessage: (provided, state) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '1.5rem',
    backgroundColor: state.isFocused ? '#f9f9f9' : 'transparent',
    color: state.isSelected ? 'inherit' : 'inherit',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:active': { backgroundColor: '#4ab19d' },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '1.5rem' }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '1.5rem',
  }),
  valueContainer: (provided) => ({ ...provided, padding: '2rem' }),
};

/**
 * Tracks page views with Google Analytics
 *
 * @name trackPageView
 * @function
 */
export const trackPageView = () => {
  try {
    if (window && window.ga) {
      window.ga('send', 'pageview');
    }
  } catch (error) {
    // silences the error in dev mode
    // and/or if gtag fails to load
  }
};

/**
 * Calculates the average rating from a number of ratings.
 *
 * @name getFeedbackRatingAvg
 * @function
 */
export const getFeedbackRatingAvg = (feedback) => {
  const sum = feedback.reduce((acc, cur) => acc + cur.rating, 0);
  const avg = sum / feedback.length || 0;
  return avg;
};

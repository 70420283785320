/*
 * Constants
 */
export const SET_RECORD_CONFIRMATION_STATUS = 'SET_RECORD_CONFIRMATION_STATUS';
export const CHECKING_RECORD_CONFIRMATION_STATUS =
  'CHECKING_RECORD_CONFIRMATION_STATUS';
export const CHECK_RECORD_CONFIRMATION_STATUS =
  'CHECK_RECORD_CONFIRMATION_STATUS';
export const SET_CONFIRMATION_RECORD_TYPE = 'SET_CONFIRMATION_RECORD_TYPE';
export const SET_SHOW_CONFIRMATION = 'SET_SHOW_CONFIRMATION';
export const SET_CONFIRMING_RECORD = 'SET_CONFIRMING_RECORD';
export const SET_CONFIRMATION_RECORD_ID = 'SET_CONFIRMATION_RECORD_ID';
export const SET_DECISION_TO_MAKE_AFTER_INIT =
  'SET_DECISION_TO_MAKE_AFTER_INIT';
export const SET_DECISION_RESULT = 'SET_DECISION_RESULT';
export const MAYBE_MAKE_DECISION = 'MAYBE_MAKE_DECISION';

/*
 * Actions
 */
export const setConfirmationRecordId = (payload) => ({
  type: SET_CONFIRMATION_RECORD_ID,
  payload,
});

export const setRecordConfirmationStatus = (payload) => ({
  type: SET_RECORD_CONFIRMATION_STATUS,
  payload,
});

export const checkingRecordConfirmationStatus = (payload) => ({
  type: CHECKING_RECORD_CONFIRMATION_STATUS,
  payload,
});

export const checkRecordConfirmationStatus = () => ({
  type: CHECK_RECORD_CONFIRMATION_STATUS,
});

export const setConfirmationRecordType = (payload) => ({
  type: SET_CONFIRMATION_RECORD_TYPE,
  payload,
});

export const setConfirmingRecord = (payload) => ({
  type: SET_CONFIRMING_RECORD,
  payload,
});

export const setShowConfirmation = (payload) => ({
  type: SET_SHOW_CONFIRMATION,
  payload,
});

export const setDecisionToMakeAfterInit = (payload) => ({
  type: SET_DECISION_TO_MAKE_AFTER_INIT,
  payload,
});

export const setDecisionResult = (payload) => ({
  type: SET_DECISION_RESULT,
  payload,
});

export const maybeMakeDecision = () => ({
  type: MAYBE_MAKE_DECISION,
});

import Router from 'next/router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { put, select } from 'redux-saga/effects';
// import * as Sentry from '@sentry/browser';
import * as globals from '../constants/globals';
import * as authActions from '../actions/authActions';
import * as formActions from '../actions/formActions';
import * as selectors from '../selectors/selectors';
import sagaError from './sagaError';

/**
 * Makes the login request to the login API.
 *
 * @name doLogin
 * @function
 * @returns {object} Fetch result.
 */
export function* doLogin() {
  const username = yield select(selectors.username);
  const password = yield select(selectors.password);
  const request = {
    credentials: 'same-origin',
    method: 'POST',
    redirect: 'follow',
    body: `username=${username}&password=${password}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  };
  return yield fetch(globals.LOGIN_URL, request);
}

/**
 * Makes the logout request to the logout API.
 *
 * @name doLogout
 * @function
 * @returns {object} Fetch result.
 */
function* doLogout() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    redirect: 'follow',
  };
  return yield fetch(globals.LOGOUT_URL, request);
}

/**
 * Makes the is logged in request to the auth API.
 *
 * @name doCheckIsLoggedIn
 * @function
 * @returns {object} Fetch result.
 */
function* doCheckIsLoggedIn() {
  const request = {
    credentials: 'same-origin',
    method: 'GET',
    redirect: 'follow',
  };
  const result = yield fetch(globals.AUTH_URL, request);
  return result;
}

/**
 * Handles the checking is user logged in process. Saves the details in state.
 *
 * @name checkIsLoggedIn
 * @function
 */
export function* checkIsLoggedIn() {
  try {
    yield put(showLoading());
    yield put(authActions.setCheckingIsLoggedIn(true));
    const response = yield doCheckIsLoggedIn();
    const result = yield response.json();
    if (!response.ok) {
      throw new Error(result);
    }
    if (!!result.id && !!result.type) {
      yield put(
        authActions.setAuthInfo({
          loggedInUserType: result.type,
          loggedInUserId: result.id,
          isLoggedIn: true,
        })
      );
      // Sentry.addBreadcrumb({
      // category: 'auth',
      // message: `Authenticated user ID: ${result.id}`,
      // level: 'info',
      // });
    }
  } catch (error) {
    yield put(
      authActions.setAuthInfo({
        loggedInUserType: null,
        loggedInUserId: null,
        isLoggedIn: false,
      })
    );
    sagaError(error);
  } finally {
    yield put(authActions.setCheckingIsLoggedIn(false));
    yield put(hideLoading());
  }
}

/**
 * Checks the user type is the desired one and redirects to the desired page.
 *
 * @name checkUserTypeAndMaybeRedirect
 * @function
 * @param   {string}  type Usertype
 * @returns {boolean}      Is the usertype desired
 */
export function* checkUserTypeAndMaybeRedirect(type) {
  try {
    const userType = yield select(selectors.loggedInUserType);
    if (type === userType) return true;
    if (userType === globals.TYPE_SHOOTER) {
      Router.push(globals.VIEW_SLUG_SHOOTER_ADMIN);
    } else if (userType === globals.TYPE_RFP) {
      Router.push(globals.VIEW_SLUG_RFP_ADMIN);
    }
    return false;
  } catch (error) {
    return sagaError(error);
  }
}

export function* redirectToLoginIfNotLoggedIn() {
  try {
    const isLoggedIn = yield select(selectors.isLoggedIn);
    if (!isLoggedIn) {
      yield put(authActions.setAfterLoginRedirect(Router.asPath));
      Router.push(globals.VIEW_SLUG_LOGIN);
      return false;
    }
    return true;
  } catch (error) {
    return sagaError(error);
  }
}

/**
 * Handles the redirect after a successful login. Checks if there is an
 * afterLoginRedirect marked in the state to be made. Otherwise redirects
 * to the admin view.
 *
 * @name loginRedirect
 * @function
 * @param {boolean} payload Was the login successful?
 */
export function* loginRedirect({ payload }) {
  try {
    if (payload === false) {
      return;
    }
    const afterLoginRedirect = yield select(selectors.afterLoginRedirect);
    const loggingOut = yield select(selectors.loggingOut);
    const loggedInUserId = yield select(selectors.loggedInUserId);
    const userType = yield select(selectors.loggedInUserType);
    const defaultRedirect =
      userType === globals.TYPE_SHOOTER
        ? globals.VIEW_SLUG_SHOOTER_ADMIN
        : globals.VIEW_SLUG_RFP_ADMIN;
    if (!loggedInUserId) {
      return;
    }
    if (loggingOut !== true) {
      const destination = afterLoginRedirect || defaultRedirect;
      if (Router.asPath !== destination) {
        Router.push(destination);
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Handles the redirect from the login view if the user is already logged in.
 *
 * @name loginRedirect
 * @function
 * @param {boolean} payload Was the login successful?
 */
export function* loginRedirectWhenSignedIn({ payload }) {
  try {
    if (payload === false || Router.asPath !== '/kirjaudu') {
      return;
    }
    if (payload.loggedInUserType === globals.TYPE_SHOOTER) {
      yield Router.push(globals.VIEW_SLUG_SHOOTER_ADMIN);
    } else if (payload.loggedInUserType === globals.TYPE_RFP) {
      yield Router.push(globals.VIEW_SLUG_RFP_ADMIN);
    }
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Handles the redirect after a successful logout. Checks if there is an
 * afterLogoutRedirect marked in the state to be made. Otherwise redirects
 * to the front page.
 *
 * @name logoutRedirect
 * @function
 * @param {boolean} payload The status of IS_LOGGING_OUT
 */
export function* logoutRedirect({ payload }) {
  try {
    if (payload !== false) {
      return;
    }
    const afterLogoutRedirect = yield select(selectors.afterLogoutRedirect);
    const loggingIn = yield select(selectors.loggingIn);
    if (loggingIn !== true) {
      Router.push(afterLogoutRedirect || globals.PATHNAME_HOME);
    }
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Handles LOGIN actions. Checks if there is any trace of the user being
 * already logged in before proceeding to make the necessary calls.
 *
 * @name login
 * @function
 */
export function* login() {
  const loggingIn = yield select(selectors.loggingIn);
  const loggingOut = yield select(selectors.loggingOut);
  // const loggedInUserId = yield select(selectors.loggedInUserId);
  // const isLoggedIn = yield select(selectors.isLoggedIn);
  if (loggingIn || loggingOut) {
    return;
  }
  try {
    yield put(showLoading());
    yield put(authActions.setLoggingIn(true));
    const response = yield doLogin();
    if (response.status === 401) {
      // yield put(formActions.setFormSubmitResult(globals.FORM_SUBMIT_FAIL));
      yield put(formActions.setFormError(globals.INVALID_WRONG_PASSWORD));
    } else {
      const result = yield response.json();
      if (response.ok) {
        yield put(
          authActions.setAuthInfo({
            loggedInUserType: result.type,
            loggedInUserId: result.id,
            isLoggedIn: true,
          })
        );
        yield put(authActions.setIsLoggedIn(true));
        // Sentry.addBreadcrumb({
        // category: 'auth',
        // message: `Logged in user ID: ${result.id}`,
        // level: 'info',
        // });
      }
    }
    if (response.status === 401) {
      yield put(formActions.resetFormAfterFailedLogin());
    } else {
      // throw new Error(result.error.message);
    }
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(authActions.setLoggingIn(false));
    yield put(hideLoading());
  }
}

/**
 * Handles LOGOUT actions. Checks if there is any trace of the user being
 * already logged out before proceeding to make the necessary calls.
 *
 * @name logout
 * @function
 */
export function* logout() {
  try {
    yield put(showLoading());
    yield put(authActions.setLoggingOut(true));
    const response = yield doLogout();
    if (!response.ok) {
      throw new Error();
    }
    yield put(
      authActions.setAuthInfo({
        loggedInUserType: null,
        loggedInUserId: null,
        isLoggedIn: false,
      })
    );
    // Sentry.addBreadcrumb({
    // category: 'auth',
    // message: `Logged out user ID: ${loggedInUserId}`,
    // level: 'info',
    // });
  } catch (error) {
    sagaError(error, 'Uloskirjautuminen ei onnistunut');
  } finally {
    yield put(authActions.setLoggingOut(false));
    yield put(hideLoading());
  }
}

/*
 * Constants
 */
export const INIT_VIEW = 'INIT_VIEW';
export const SET_INITIALIZING_VIEW = 'SET_INITIALIZING_VIEW';

/**
 * Action to initialize a view. Intercepted by a saga that does the heavy lifting.
 *
 * @name initView
 * @function
 * @param   {string} pathname The pathname of the view.
 * @param   {mixed}  payload  Extra details for view, e.g. an ID.
 * @returns {object}          Plain object action.
 */
export const initView = (pathname, payload) => ({
  type: INIT_VIEW,
  pathname,
  payload,
});

/**
 * Action to set the initializing view flag.
 *
 * @name setInitializingView
 * @function
 * @param   {boolean} payload=true Are we setting the flag on or off.
 * @returns {object}               Plain object action.
 */
export const setInitializingView = (payload = true) => ({
  type: SET_INITIALIZING_VIEW,
  payload,
});

// Urls
const AUTH_BASE = `/api/auth`;
export const AUTH_URL = `${AUTH_BASE}/check`;
export const LOGIN_URL = `${AUTH_BASE}/login`;
export const LOGOUT_URL = `${AUTH_BASE}/logout`;
export const CONF_URL = '/ok';
export const CONF_QUERY_PARAM = 'id';
export {
  ACCEPT_URL,
  AFFIRMER_CUSTOMER,
  AFFIRMER_SHOOTER,
  BUDGETS,
  BUDGET_FILTER_OFF,
  BUDGET_FILTER_OVER,
  BUDGET_FILTER_UNDER,
  BUDGET_NA,
  CONFIRM_URL,
  DECISION_ACCEPT,
  DECISION_DENY,
  DENY_URL,
  INVALID_EMAIL_HAS_RFP_OPEN,
  INVALID_EMAIL_TAKEN_FOR_SHOOTER,
  LOCATION_FILTER_BLACKLIST,
  LOCATION_FILTER_OFF,
  LOCATION_FILTER_WHITELIST,
  LOGIN_METHOD_USERNAME_PASSWORD,
  PARAM_START,
  RFP_SUBMIT_DEADLINE_BEFORE_WEDDING_DAYS,
  RFP_EARLIEST_CLOSE_TIME_DAYS,
  TYPE_RESET,
  TYPE_RFP,
  TYPE_SHOOTER,
  photoTypes,
} from './constants';
export const API_URL = `${process.env.NEXT_PUBLIC_APP_URL}/api`;
export const PROPOSALS_URL = `${API_URL}/proposals`;
export const CHOOSE_PROPOSAL_URL = `${PROPOSALS_URL}/select`;
export const CANCEL_PROPOSAL_URL = `${PROPOSALS_URL}/cancel`;
export const EMAIL_CHECK_URL = `${API_URL}/checkEmail`;
export const FEEDBACK_URL = `${API_URL}/feedback`;
export const HOME_FEEDBACK_URL = `${FEEDBACK_URL}/set/home`;
export const PUBLIC_FEEDBACK_URL = `${FEEDBACK_URL}/public`;
export const FORGOT_URL = `${API_URL}/forgot`;
export const GALLERY_URL = `${API_URL}/gallery`;
export const INVOICE_URL = `${API_URL}/invoice`;
export const QUESTIONS_URL = `${API_URL}/questions`;
export const RECORD_TYPE_CHECK_URL = `${API_URL}/checkType`;
export const RFPS_URL = `${API_URL}/rfps`;
export const RFPS_ALL_URL = `${API_URL}/rfps/all`;
export const RFP_URL = `${API_URL}/rfps/rfp`;
export const CLOSE_REQUEST_URL = `${API_URL}/rfps/close`;
export const SHOOTERS_URL = `${API_URL}/shooters`;
export const SHOOTERS_ALL_URL = `${SHOOTERS_URL}/all`;
export const SHOOTERS_LIST_URL = `${SHOOTERS_URL}`;
export const SHOOTERS_SINGLE_URL = `${SHOOTERS_URL}/public`;
export const SHOOTER_URL = `${SHOOTERS_URL}/shooter`;
export const SURVEY_URL = `${API_URL}/survey`;

// Pathnames
export const PATHNAME_CONFIRM = '/confirm';
export const PATHNAME_CREATE_PASSWORD = '/createPassword';
export const PATHNAME_DASHBOARD = '/dashboard';
export const PATHNAME_FORGOT = '/forgot';
export const PATHNAME_HOME = '/';
export const PATHNAME_JOIN = '/join';
export const PATHNAME_LOGIN = '/login';
export const PATHNAME_LOGOUT = '/logout';
export const PATHNAME_PROPOSALS = '/proposals';
export const PATHNAME_SHOOTERS_LIST = '/shooters';
export const PATHNAME_SURVEY = '/survey';

// Hero types
export const HEROTYPE_SHOOTER = 'signup';
export const HEROTYPE_HOME = 'home';
export const HEROTYPE_LOGIN = 'home';
export const HEROTYPE_CREATE_PASSWORD = 'create-password';

// Invalid answer types
export const INVALID_EMPTY = 'EMPTY';
export const INVALID_NONSELECTED = 'NONSELECTED';
export const INVALID_EMAIL_INVALID = 'EMAIL_INVALID';
export const INVALID_MALFORMED_DATE = 'MALFORMED_DATE';
export const INVALID_TOO_LATE = 'TOO_LATE';
export const INVALID_IN_PAST = 'IN_PAST';
export const INVALID_TOO_SOON = 'TOO_SOON';
export const INVALID_LOCATION = 'INVALID_LOCATION';
export const INVALID_NAMES = 'INVALID_NAMES';
export const INVALID_ORGCODE = 'INVALID_ORGCODE';
export const INVALID_EMAIL_UNAVAILABLE = 'EMAIL_UNAVAILABLE';
export const INVALID_PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT';
export const INVALID_PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH';
export const INVALID_LOGIN = 'INVALID_LOGIN';
export const INVALID_WWW = 'INVALID_WWW';
export const INVALID_WRONG_PASSWORD = 'INVALID_WRONG_PASSWORD';

// Form submit results
export const FORM_SUBMIT_SUCCESS = 'SUCCESS';
export const FORM_SUBMIT_FAIL = 'FAIL';

// Views
export const VIEW_ADMIN = 'admin';
export const VIEW_RFPS = 'tarjouspyynnot';
export const VIEW_SHOOTERINFO = 'perustiedot';

// View slugs
export const VIEW_SLUG_ADMIN = '/admin';
export const VIEW_SLUG_SHOOTERINFO = '/hallinta/omat-tiedot/perustiedot';
export const VIEW_SLUG_RFPS = '/hallinta/tarjouspyynnot/avoimet';
export const VIEW_SLUG_LOGIN = '/kirjaudu';
export const VIEW_SLUG_LOGOUT = '/kirjaudu-ulos';
export const VIEW_SLUG_JOIN = '/liity';
export const VIEW_SLUG_SHOOTER_ADMIN = '/hallinta/tarjouspyynnot/avoimet';
export const VIEW_SLUG_RFP_ADMIN = '/tarjoukset';
export const VIEW_SLUG_FORGOT = '/unohdin-salasanan';

// Dashboard tabs
export const SLUG_ADMIN = 'admin';
export const SLUG_RFPS = 'rfps';
export const SLUG_SHOOTERINFO = 'shooter';
export const SLUG_PROPOSALS = 'proposals';

// Admin list tabs
export const TAB_ADMIN_PAST = 'menneet';
export const TAB_ADMIN_FORTHCOMING = 'tulevat';
export const TAB_ADMIN_ALL = 'kaikki';

// RFP list tabs
export const TAB_RFP_OPEN = 'avoimet';
export const TAB_RFP_CLOSED = 'suljetut';
export const TAB_RFP_PROPOSED = 'tarjotut';
export const TAB_RFP_CANCELLED = 'perutut';
export const TAB_RFP_ALL = 'kaikki';

// Shooter list tabs
export const TAB_SHOOTERINFO_DETAILS = 'perustiedot';
export const TAB_SHOOTERINFO_GALLERY = 'galleria';
export const TAB_SHOOTERINFO_FEEDBACK = 'palaute';

// Request states
export const REQUEST_STATE_CANCELLABLE = 'REQUEST_STATE_CANCELLABLE';
export const REQUEST_STATE_CANCELLED = 'REQUEST_STATE_CANCELLED';
export const REQUEST_STATE_CLOSED = 'REQUEST_STATE_CLOSED';
export const REQUEST_STATE_LOST = 'REQUEST_STATE_LOST';
export const REQUEST_STATE_MISSING_INFO = 'REQUEST_STATE_MISSING_INFO';
export const REQUEST_STATE_PROPOSABLE = 'REQUEST_STATE_PROPOSABLE';
export const REQUEST_STATE_PROPOSED = 'REQUEST_STATE_PROPOSED';
export const REQUEST_STATE_UNPAID_INVOICES = 'REQUEST_STATE_UNPAID_INVOICES';
export const REQUEST_STATE_WON = 'REQUEST_STATE_WON';

// Formcontexts
export const FORMCONTEXT_PROPOSAL = 'FORMCONTEXT_PROPOSAL';

// Modal messages
export const MODAL_MESSAGE_LINK = 'MODAL_MESSAGE_LINK';
export const MODAL_MESSAGE_CONDITIONS = 'MODAL_MESSAGE_CONDITIONS';
export const MODAL_MESSAGE_CONFIRM_PROPOSAL = 'MODAL_MESSAGE_CONFIRM_PROPOSAL';
export const MODAL_MESSAGE_CONFIRM_RFP_CLOSE =
  'MODAL_MESSAGE_CONFIRM_RFP_CLOSE';
export const MODAL_MESSAGE_PRIVACY = 'MODAL_MESSAGE_PRIVACY';
export const MODAL_MESSAGE_AFFIRM = 'MODAL_MESSAGE_AFFIRM';
export const MODAL_MESSAGE_CANCEL = 'MODAL_MESSAGE_CANCEL';

// Request ations
export const PROPOSAL_ACTION_AFFIRM = 'affirm';
export const PROPOSAL_ACTION_CANCEL = 'cancel';

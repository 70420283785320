import moment from 'moment';
import { put, select, delay } from 'redux-saga/effects';
import validateAnswer from './validationSaga';
import * as formActions from '../actions/formActions';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';
import * as authActions from '../actions/authActions';
import * as createPasswordActions from '../actions/createPasswordActions';
import sagaError from './sagaError';

/**
 * Constructs and submits the Request For Proposal form.
 *
 * @name doSubmitRfpForm
 * @function
 * @returns {object} Fetch result
 */
export function* doSubmitRfpForm() {
  // Standardize dates
  const answers = yield select(selectors.answers);
  answers.QUESTION_DATE = moment(answers.QUESTION_DATE, 'DD.MM.YYYY').format();
  // Make the call
  const json = JSON.stringify(answers);
  const request = {
    method: 'POST',
    redirect: 'follow',
    body: json,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return yield fetch(globals.RFPS_URL, request);
}

/**
 * Constructs and submits the Request For Proposal form.
 *
 * @name doSubmitShooterForm
 * @function
 * @returns {object} Fetch result.
 */
export function* doSubmitShooterForm() {
  const answers = yield select(selectors.answers);
  const json = JSON.stringify(answers);
  const request = {
    method: 'POST',
    redirect: 'follow',
    body: json,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return yield fetch(globals.SHOOTERS_URL, request);
}

/**
 * Constructs and submits the forgot form.
 *
 * @name doSubmitForgotForm
 * @function
 * @returns {object} Fetch result.
 */
export function* doSubmitForgotForm() {
  const answers = yield select(selectors.answers);
  const json = JSON.stringify(answers);
  const request = {
    method: 'POST',
    redirect: 'follow',
    body: json,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return yield fetch(globals.FORGOT_URL, request);
}

/**
 * Determines which type of form to submit and forwards it to submitting.
 *
 * @name doSubmitForm
 * @function
 * @returns {object} Fetch result.
 */
export function* doSubmitForm() {
  const type = yield select(selectors.formType);
  if (type === globals.PATHNAME_HOME) {
    yield put(formActions.submitRfpForm());
    return yield doSubmitRfpForm();
  }
  if (type === globals.PATHNAME_JOIN) {
    yield put(formActions.submitNewShooterForm());
    return yield doSubmitShooterForm();
  }
  if (type === globals.PATHNAME_FORGOT) {
    yield put(formActions.submitForgotPasswordForm());
    return yield doSubmitForgotForm();
  }
  return '';
}

/**
 * Actually send the form forward to be submitted and handle the state
 * around that.
 *
 * @name actuallySubmitForm
 * @function
 */
export function* actuallySubmitForm() {
  try {
    yield put(formActions.setSubmittingForm(true));
    yield delay(1000);
    // Intercept login submission here and send off to be handled in authSaga.
    const type = yield select(selectors.formType);
    if (type === globals.PATHNAME_LOGIN) {
      yield put(authActions.login());
      return;
    }
    // Intercept password creation here and send off to be handled in its saga.
    if (type === globals.PATHNAME_CREATE_PASSWORD) {
      yield put(createPasswordActions.createPassword());
      return;
    }
    // If it is not a login form, continue with data submission.
    const response = yield doSubmitForm();
    const result = yield response.json();
    if (response.ok) {
      yield put(formActions.setFormSubmitResult(globals.FORM_SUBMIT_SUCCESS));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    sagaError(error);
    yield put(formActions.setFormSubmitResult(globals.FORM_SUBMIT_FAIL));
  } finally {
    yield put(formActions.setSubmittingForm(false));
  }
}

/**
 * Handles the event when an answer is submitted on a form. Usually this
 * results in incrementing the question and storing the answers. However,
 * if it is the last question it submits the form.
 *
 * @name handleSubmitForm
 * @function
 */
export function* handleSubmitForm() {
  try {
    yield validateAnswer();
    const formError = yield select(selectors.formError);
    if (formError) {
      return;
    }
    // Fade out the previous answer
    yield put(formActions.setQuestionAnswered(true));
    // Update the progress bar
    yield delay(300);
    // Was it the last question?
    const isLastQuestion = yield select(selectors.isLastQuestion);
    if (isLastQuestion) {
      yield actuallySubmitForm();
    } else {
      // No, show the next question.
      yield put(formActions.questionIncrement());
      // Show the input text again
      yield put(formActions.setQuestionAnswered(false));
    }
  } catch (error) {
    sagaError(error);
  }
}

/**
 * Dispatches an action to set the form slug.
 * Is the same as form type without the leading slash.
 *
 * @name setFormSlug
 * @function
 * @param {string} {payload} Form type i.e. PATHNAME_LOGIN
 */
export function* setFormSlug({ payload }) {
  let slug = payload;
  if (payload.indexOf('/') === 0) {
    slug = slug.substring(1);
  }
  yield put(formActions.setFormSlug(slug));
}
